export function DoctorIcon() {
  return (
    <svg fill="none" viewBox="0 0 36 45" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 39.2783V37.7355V36.4129C1 31.0591 5.57079 26.679 11.1578 26.679H19.3679C24.9549 26.679 29.5257 31.0591 29.5257 36.4129V37.7355V39.2783"
        fill="#B0B7E4"
      />
      <path
        d="M1 39.2783V37.7355V36.4129C1 31.0591 5.57079 26.679 11.1578 26.679H19.3679C24.9549 26.679 29.5257 31.0591 29.5257 36.4129V37.7355V39.2783"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6253 24.1694V27.0488C18.6253 28.9052 17.1206 30.4102 15.2623 30.4102C13.4061 30.4102 11.9014 28.9052 11.9014 27.0488V24.1879"
        fill="white"
      />
      <path
        d="M18.6253 24.1694V27.0488C18.6253 28.9052 17.1206 30.4102 15.2623 30.4102C13.4061 30.4102 11.9014 28.9052 11.9014 27.0488V24.1879"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1539 2.48012C23.8178 3.00308 25.0872 4.5812 25.0872 6.83429V12.4689L5.38867 12.5303V6.85359C5.38867 3.44028 8.15465 0.673448 11.5682 0.673448H22.6257C22.6257 2.71298 20.9704 4.36764 18.9313 4.36764H14.8273"
        fill="#FFD576"
      />
      <path
        d="M22.1539 2.48012C23.8178 3.00308 25.0872 4.5812 25.0872 6.83429V12.4689L5.38867 12.5303V6.85359C5.38867 3.44028 8.15465 0.673448 11.5682 0.673448H22.6257C22.6257 2.71298 20.9704 4.36764 18.9313 4.36764H14.8273"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7434 11.413V10.5824C22.7434 7.1473 19.9583 4.3618 16.5221 4.3618H13.9562C10.5222 4.3618 7.7371 7.1473 7.7371 10.5824V11.4156C6.02867 11.4392 4.65332 12.8273 4.65332 14.5398C4.65332 16.2523 6.02867 17.6403 7.7371 17.6648V17.9195C7.7371 21.6735 10.7801 24.7178 14.5343 24.7178H15.9462C19.7005 24.7178 22.7434 21.6735 22.7434 17.9195V17.6665C24.4712 17.6665 25.8702 16.2667 25.8702 14.5398C25.8702 12.8129 24.4712 11.413 22.7434 11.413Z"
        fill="white"
      />
      <path
        d="M22.7434 11.413V10.5824C22.7434 7.1473 19.9583 4.3618 16.5221 4.3618H13.9562C10.5222 4.3618 7.7371 7.1473 7.7371 10.5824V11.4156C6.02867 11.4392 4.65332 12.8273 4.65332 14.5398C4.65332 16.2523 6.02867 17.6403 7.7371 17.6648V17.9195C7.7371 21.6735 10.7801 24.7178 14.5343 24.7178H15.9462C19.7005 24.7178 22.7434 21.6735 22.7434 17.9195V17.6665C24.4712 17.6665 25.8702 16.2667 25.8702 14.5398C25.8702 12.8129 24.4712 11.413 22.7434 11.413Z"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6885 36.2927V40.8335C29.6885 42.3242 28.4806 43.5322 26.9903 43.5322C25.5001 43.5322 24.29 42.3242 24.29 40.8335V36.2927"
        fill="#FFD576"
      />
      <path
        d="M29.6885 36.2927V40.8335C29.6885 42.3242 28.4806 43.5322 26.9903 43.5322C25.5001 43.5322 24.29 42.3242 24.29 40.8335V36.2927"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.037 31.7884V35.2135C31.037 35.8097 30.5551 36.293 29.9576 36.293H24.0218C23.4243 36.293 22.9424 35.8097 22.9424 35.2135V31.7842"
        fill="#B0B7E4"
      />
      <path
        d="M31.037 31.7884V35.2135C31.037 35.8097 30.5551 36.293 29.9576 36.293H24.0218C23.4243 36.293 22.9424 35.8097 22.9424 35.2135V31.7842"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9068 30.3808C29.6394 33.6478 24.3413 33.6478 21.0739 30.3808C17.8087 27.1137 17.8087 21.8171 21.0739 18.5499C24.3413 15.2836 29.6394 15.2836 32.9068 18.5499C36.172 21.8171 36.172 27.1137 32.9068 30.3808Z"
        fill="white"
      />
      <path
        d="M32.9068 30.3808C29.6394 33.6478 24.3413 33.6478 21.0739 30.3808C17.8087 27.1137 17.8087 21.8171 21.0739 18.5499C24.3413 15.2836 29.6394 15.2836 32.9068 18.5499C36.172 21.8171 36.172 27.1137 32.9068 30.3808Z"
        stroke="#822949"
        strokeWidth="1.22126"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
