export function TestResultsIcon() {
  return (
    <svg fill="none" viewBox="0 0 45 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.05762 11.1936V26.3136C9.05762 27.6001 10.2016 28.6426 11.6146 28.6426H12.2351C13.6481 28.6426 14.7921 27.6001 14.7921 26.3136V11.1936H9.05762Z"
        fill="#FFDCF0"
      />
      <path
        d="M9.05762 11.1936V26.3136C9.05762 27.6001 10.2016 28.6426 11.6146 28.6426H12.2351C13.6481 28.6426 14.7921 27.6001 14.7921 26.3136V11.1936H9.05762Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7921 3.15926H9.05762V11.1934H14.7921V3.15926Z"
        fill="white"
      />
      <path
        d="M14.7921 3.15926H9.05762V11.1934H14.7921V3.15926Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3398 3.15937H19.6064V21.2803H25.3398V3.15937Z"
        fill="white"
      />
      <path
        d="M25.3398 3.15937H19.6064V21.2803H25.3398V3.15937Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6064 21.2804V26.3137C19.6064 27.6002 20.7486 28.6426 22.1621 28.6426H22.7842C24.1951 28.6426 25.3398 27.6002 25.3398 26.3137V21.2804H19.6064Z"
        fill="#FFDCF0"
      />
      <path
        d="M19.6064 21.2804V26.3137C19.6064 27.6002 20.7486 28.6426 22.1621 28.6426H22.7842C24.1951 28.6426 25.3398 27.6002 25.3398 26.3137V21.2804H19.6064Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8876 3.15917H30.1533V11.1934H35.8876V3.15917Z"
        fill="white"
      />
      <path
        d="M35.8876 3.15917H30.1533V11.1934H35.8876V3.15917Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1533 11.1937V26.3136C30.1533 27.6001 31.2971 28.6426 32.709 28.6426H33.332C34.7438 28.6426 35.8876 27.6001 35.8876 26.3136V11.1937H30.1533Z"
        fill="#FFDCF0"
      />
      <path
        d="M30.1533 11.1937V26.3136C30.1533 27.6001 31.2971 28.6426 32.709 28.6426H33.332C34.7438 28.6426 35.8876 27.6001 35.8876 26.3136V11.1937H30.1533Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1434 24.2148H9.05664"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1434 21.6211H9.05664"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1434 19.0264H9.05664"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1434 16.4316H9.05664"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1434 13.8369H9.05664"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6917 24.2148H19.6055"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6917 19.0264H19.6055"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6917 16.4316H19.6055"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6917 13.8369H19.6055"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2396 24.2148H30.1543"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2396 21.6201H30.1543"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2396 19.0264H30.1543"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2396 16.4316H30.1543"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2396 13.8369H30.1543"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1459 3.15918H8.70286C8.21174 3.15918 7.81543 2.76165 7.81543 2.27198V2.03639C7.81543 1.54575 8.21174 1.14798 8.70286 1.14798H15.1459C15.637 1.14798 16.0333 1.54575 16.0333 2.03639V2.27198C16.0333 2.76165 15.637 3.15918 15.1459 3.15918Z"
        fill="#B0B7E4"
      />
      <path
        d="M15.1459 3.15918H8.70286C8.21174 3.15918 7.81543 2.76165 7.81543 2.27198V2.03639C7.81543 1.54575 8.21174 1.14798 8.70286 1.14798H15.1459C15.637 1.14798 16.0333 1.54575 16.0333 2.03639V2.27198C16.0333 2.76165 15.637 3.15918 15.1459 3.15918Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6932 3.15918H19.2509C18.7617 3.15918 18.3623 2.76131 18.3623 2.27159V2.03596C18.3623 1.54549 18.7617 1.14787 19.2509 1.14787H25.6932C26.1849 1.14787 26.5817 1.54549 26.5817 2.03596V2.27159C26.5817 2.76131 26.1849 3.15918 25.6932 3.15918Z"
        fill="#B0B7E4"
      />
      <path
        d="M25.6932 3.15918H19.2509C18.7617 3.15918 18.3623 2.76131 18.3623 2.27159V2.03596C18.3623 1.54549 18.7617 1.14787 19.2509 1.14787H25.6932C26.1849 1.14787 26.5817 1.54549 26.5817 2.03596V2.27159C26.5817 2.76131 26.1849 3.15918 25.6932 3.15918Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.2416 3.15918H29.7987C29.3096 3.15918 28.9102 2.76174 28.9102 2.27187V2.03629C28.9102 1.54565 29.3096 1.14822 29.7987 1.14822H36.2416C36.7332 1.14822 37.1302 1.54565 37.1302 2.03629V2.27187C37.1302 2.76174 36.7332 3.15918 36.2416 3.15918Z"
        fill="#B0B7E4"
      />
      <path
        d="M36.2416 3.15918H29.7987C29.3096 3.15918 28.9102 2.76174 28.9102 2.27187V2.03629C28.9102 1.54565 29.3096 1.14822 29.7987 1.14822H36.2416C36.7332 1.14822 37.1302 1.54565 37.1302 2.03629V2.27187C37.1302 2.76174 36.7332 3.15918 36.2416 3.15918Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1484 31.3447H1.79637C1.35654 31.3447 1 30.9884 1 30.5494V29.544C1 29.105 1.35654 28.7487 1.79637 28.7487H43.1484C43.5882 28.7487 43.9448 29.105 43.9448 29.544V30.5494C43.9448 30.9884 43.5882 31.3447 43.1484 31.3447Z"
        fill="#FFDCF0"
      />
      <path
        d="M43.1484 31.3447H1.79637C1.35654 31.3447 1 30.9884 1 30.5494V29.544C1 29.105 1.35654 28.7487 1.79637 28.7487H43.1484C43.5882 28.7487 43.9448 29.105 43.9448 29.544V30.5494C43.9448 30.9884 43.5882 31.3447 43.1484 31.3447Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.60051 28.748H3.35156V8.55697H5.60051V28.748Z"
        fill="#B0B7E4"
      />
      <path
        d="M5.60051 28.748H3.35156V8.55697H5.60051V28.748Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.5938 28.749H39.3447V8.5577H41.5938V28.749Z" fill="#B0B7E4" />
      <path
        d="M41.5938 28.749H39.3447V8.5577H41.5938V28.749Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.70117 8.14943V7.1413C1.70117 6.91551 1.88306 6.73207 2.1091 6.73207H42.8369C43.0604 6.73207 43.2449 6.91551 43.2449 7.1413V8.14943C43.2449 8.37496 43.0604 8.55762 42.8369 8.55762H2.1091C1.88306 8.55762 1.70117 8.37496 1.70117 8.14943Z"
        fill="#FFDCF0"
      />
      <path
        d="M1.70117 8.14943V7.1413C1.70117 6.91551 1.88306 6.73207 2.1091 6.73207H42.8369C43.0604 6.73207 43.2449 6.91551 43.2449 7.1413V8.14943C43.2449 8.37496 43.0604 8.55762 42.8369 8.55762H2.1091C1.88306 8.55762 1.70117 8.37496 1.70117 8.14943Z"
        stroke="#822949"
        strokeWidth="1.13156"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
