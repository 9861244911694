import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled, { StyledComponent } from "styled-components";
import { useTheme } from "@material-ui/styles";
import { useUniqueId } from "storybook/utils/use-unique-id";
import {
  StyledTabTextDefault,
  StyledTabsHeadDefault,
  StyledTabsButtonDefault,
  StyledTabsButtonActiveDefault,
} from "./theme/default";
import {
  StyledTabTextLegacy,
  StyledTabsHeadLegacy,
  StyledTabsButtonLegacy,
  StyledTabsButtonActiveLegacy,
} from "./theme/legacy";
import {
  StyledTabTextFuture,
  StyledTabsHeadFuture,
  StyledTabsButtonFuture,
  StyledTabsButtonActiveFuture,
} from "./theme/future";
import {
  TabsProps,
  TabsHeadProps,
  TabsButtonProps,
  TabsPanelProps,
  TabsThemeType,
  TabButtonType,
  TabsContextType,
} from "./types";
import { ComponentThemeProvider } from "storybook/context/component-theme";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StyledComponentMap = Record<TabsProps["theme"], StyledComponent<any, any>>;

const styledTabsHeadMap: StyledComponentMap = {
  default: StyledTabsHeadDefault,
  legacy: StyledTabsHeadLegacy,
  future: StyledTabsHeadFuture,
};

const styledTabTextMap: StyledComponentMap = {
  default: StyledTabTextDefault,
  legacy: StyledTabTextLegacy,
  future: StyledTabTextFuture,
};

const StyledTabsPanel = styled.div`
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const TabContext = createContext({} as TabsContextType);

export const useTabContext = (): TabsContextType => useContext(TabContext);

function TabsHead({ children }: TabsHeadProps): JSX.Element {
  const theme: TabsThemeType = useTheme();
  const StyledTabsHead = styledTabsHeadMap[theme.name];
  const { mobileStackTabs } = useTabContext();

  return (
    <StyledTabsHead
      role="tablist"
      $mobileStackTabs={mobileStackTabs}
      as={styledTabTextMap[theme.name]}
    >
      {children}
    </StyledTabsHead>
  );
}

const styledTabsButtonMap: StyledComponentMap = {
  default: StyledTabsButtonDefault,
  legacy: StyledTabsButtonLegacy,
  future: StyledTabsButtonFuture,
};

const styledTabsButtonActiveMap: StyledComponentMap = {
  default: StyledTabsButtonActiveDefault,
  legacy: StyledTabsButtonActiveLegacy,
  future: StyledTabsButtonActiveFuture,
};

function TabsButton({
  id,
  route,
  disabled,
  children,
}: TabsButtonProps): JSX.Element {
  const theme: TabsThemeType = useTheme();
  const StyledTabsButton = styledTabsButtonMap[theme.name];
  const StyledTabsButtonActive = styledTabsButtonActiveMap[theme.name];
  const { activeTabId, instanceId, setActiveTabId, mobileStackTabs } =
    useTabContext();
  const buttonRef = useRef<HTMLDivElement | HTMLAnchorElement>(null);
  const isMatch = useRouteMatch(route || "");
  const isValidMatch = Boolean(route && isMatch && isMatch.isExact);

  useEffect(() => {
    if (route && isValidMatch) {
      setActiveTabId(id);
    }
  }, [route, id, isValidMatch, setActiveTabId]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setActiveTabId(id);
    }
  }, [disabled, id, setActiveTabId]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      let newTab: TabButtonType | null = null;

      if (event.key === "ArrowRight") {
        newTab = event.currentTarget.nextElementSibling as TabButtonType;
      } else if (event.key === "ArrowLeft") {
        newTab = event.currentTarget.previousElementSibling as TabButtonType;
      } else if ((!route && event.key === "Enter") || event.key === " ") {
        handleClick();
        event.preventDefault();
      }

      if (newTab && newTab.getAttribute("role") === "tab") {
        newTab.focus();
      }
    },
    [handleClick, route]
  );

  const isActive = activeTabId === id;
  const tabId = `${instanceId}-${id}`;

  const sharedTabProps = {
    role: "tab",
    id: `tab-${tabId}`,
    onKeyDown: handleKeyDown,
    "aria-selected": isActive,
    "aria-controls": `tabpanel-${tabId}`,
    tabIndex: disabled ? -1 : 0,
    ...(typeof children === "string" ? { title: children } : {}),
  };

  let extraTabProps: Record<string, unknown>;
  if (route) {
    extraTabProps = {
      as: Link,
      to: {
        pathname: route,
        search: window.location.search,
      },
    };
  } else {
    extraTabProps = {
      onClick: handleClick,
    };
  }

  const TabsButton = isActive ? StyledTabsButtonActive : StyledTabsButton;

  return (
    <TabsButton
      ref={buttonRef}
      $disabled={disabled}
      data-id={`tab-${id}`}
      data-testid="Tabs__Button"
      $mobileStackTabs={mobileStackTabs}
      {...sharedTabProps}
      {...extraTabProps}
    >
      {children}
    </TabsButton>
  );
}

function TabsPanel({ children, id }: TabsPanelProps): JSX.Element | null {
  const { activeTabId, instanceId } = useTabContext();
  const tabId = `${instanceId}-${id}`;

  if (activeTabId !== id) return null;

  return (
    <StyledTabsPanel
      tabIndex={0}
      role="tabpanel"
      id={`tabpanel-${tabId}`}
      data-testid="Tabs__Panel"
      aria-labelledby={`tab-${tabId}`}
    >
      {children}
    </StyledTabsPanel>
  );
}

function Tabs({
  theme,
  children,
  initialActiveId,
  mobileStackTabs = true,
}: TabsProps): JSX.Element {
  const [activeTabId, setActiveTabId] = useState<string>(initialActiveId);
  const instanceId = useUniqueId("Tabs");

  const contextProps = useMemo(
    () => ({
      instanceId,
      activeTabId,
      setActiveTabId,
      mobileStackTabs,
    }),
    [activeTabId, instanceId, mobileStackTabs]
  );

  return (
    <ComponentThemeProvider theme={theme}>
      <TabContext.Provider value={contextProps}>{children}</TabContext.Provider>
    </ComponentThemeProvider>
  );
}

TabsButton.displayName = "Tabs.Button";
TabsHead.displayName = "Tabs.Head";
TabsPanel.displayName = "Tabs.Panel";

Tabs.Button = TabsButton;
Tabs.Head = TabsHead;
Tabs.Panel = TabsPanel;

export { Tabs };
export type { TabsProps };
