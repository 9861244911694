import { useCallback } from "react";
import { format } from "date-fns";
import { Route } from "react-router-dom";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ReportContainer } from "pages/patient-results/styles";
import { RiskReportProvider } from "context/risk-report";
import { useRiskReport } from "context/risk-report";
import { downloadPdf } from "helpers/download-pdf";
import { Loading } from "components/loading";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { DownloadButtonWrapper, DateWrapper } from "./styles";

function RiskReportDisplay() {
  const { reportHtml, reportPdf, reportData, isLoading } = useRiskReport();
  const date = reportData?.date || "";

  const handleDownloadClick = useCallback(() => {
    downloadPdf({ reportPdf });
  }, [reportPdf]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ReportContainer role="article">
      {date && (
        <DateWrapper>
          <Typography variant="body2" theme="default" color="ciqBrandDark">
            <b>Date: {format(new Date(date as string), "MM/dd/yyyy")}</b>
          </Typography>
        </DateWrapper>
      )}
      <DownloadButtonWrapper>
        <Button
          theme="default"
          variant="outlined"
          fullWidth={true}
          startIcon={<GetAppIcon />}
          onClick={handleDownloadClick}
        >
          Download
        </Button>
      </DownloadButtonWrapper>
      {HtmlParser(reportHtml)}
    </ReportContainer>
  );
}

export function RiskReport() {
  return (
    <Route exact path="/reports/:reportId">
      <RiskReportProvider>
        <RiskReportDisplay />
      </RiskReportProvider>
    </Route>
  );
}
