import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { CircularProgress } from "@material-ui/core";
import { format, parseISO, isAfter, isBefore } from "date-fns";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useForm } from "storybook/utils/form";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { Loading } from "components/loading";
import {
  MainWrapper,
  FormContainer,
  IsSubmittingWrapper,
  ErrorWrapper,
} from "./styles";

const minDate = new Date("1900-01-01");
const maxDate = new Date();

const isDateInRange = (date: Date) => {
  return isAfter(date, minDate) && isBefore(date, maxDate);
};

const patientSchema = z.object({
  patient: z
    .object({
      firstName: z.string().min(1, { message: "Please enter your first name" }),
      lastName: z.string().min(1, { message: "Please enter your last name" }),
      dateOfBirth: z
        .string()
        .refine(
          (value) => {
            const date = parseISO(value);
            return !isNaN(date.getTime()) && isDateInRange(date);
          },
          {
            message: "Please enter a valid date",
          }
        )
        .transform((value) => {
          const date = parseISO(value);
          return format(date, "yyyy-MM-dd");
        }),
    })
    .required()
    .strict(),
});

type Patient = z.infer<typeof patientSchema>;

export function PersonalInformation() {
  const [formValues, setFormValues] = useState<Patient>({
    patient: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const patientId = localStorage.getItem("ciq_id");
  const idToken = localStorage.getItem("id_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await result.json();

        setFormValues({
          patient: {
            firstName: data.person.first_name,
            lastName: data.person.last_name,
            dateOfBirth: data.person.date_of_birth,
          },
        });
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idToken, patientId]);

  const handleSubmit = useCallback(
    async (formData) => {
      setIsSubmitting(true);
      try {
        const result = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              first_name: formData.patient.firstName,
              last_name: formData.patient.lastName,
              date_of_birth: formData.patient.dateOfBirth,
            }),
          }
        );
        if (result.ok) {
          setIsSubmitting(false);
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [idToken, patientId]
  );

  const { Form, Input, DateOfBirth } = useForm<Patient>({
    schema: patientSchema,
    values: formValues,
    mode: "onChange",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <MainWrapper>
      <Form onSubmit={handleSubmit} disableAutocomplete={true}>
        <FormContainer>
          <Input
            theme="default"
            name="patient.firstName"
            label="First Name"
            shrinkLabel={true}
          />
          <Input
            theme="default"
            name="patient.lastName"
            label="Last Name"
            shrinkLabel={true}
          />
          <DateOfBirth
            theme="default"
            name="patient.dateOfBirth"
            maxMonthListHeight="30vh"
            disablePortal={false}
            showLabel={false}
          />
        </FormContainer>
        <Button
          theme="default"
          variant="contained"
          color="default"
          type="submit"
          fullWidth={true}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <IsSubmittingWrapper>
              <span>Saving</span>
              <CircularProgress color="inherit" />
            </IsSubmittingWrapper>
          ) : (
            "Save"
          )}
        </Button>
      </Form>
      {error && (
        <ErrorWrapper>
          <Typography theme="default" variant="body1" color="error">
            There was an issue with your request. Please try again.
          </Typography>
        </ErrorWrapper>
      )}
    </MainWrapper>
  );
}
