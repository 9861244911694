import styled from "styled-components";
import { tablet } from "styles/breakpoints";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 250px;
    height: 92.8px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      width: 387px;
      height: 151.39px;
    }
  }
`;

export function SendCodeGraphic() {
  return (
    <Wrapper>
      <svg
        width="250"
        height="96"
        fill="none"
        viewBox="0 0 250 96"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          x="0"
          y="0"
          width="250"
          height="96"
          id="mask0_2176_5324"
          maskUnits="userSpaceOnUse"
        >
          <path d="M250 0H0V95.2138H250V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2176_5324)">
          <path
            d="M181.911 46.593C181.911 31.1228 194.452 18.5818 209.923 18.5818C225.392 18.5818 237.933 31.1228 237.933 46.593C237.933 62.0627 225.392 74.604 209.923 74.604C194.452 74.604 181.911 62.0627 181.911 46.593Z"
            fill="#B8EEFF"
          />
          <path
            d="M197.28 52.6329C198.318 54.5806 199.646 56.3818 201.234 57.9705L208.8 65.5353C209.422 66.1582 210.431 66.1582 211.054 65.5353L218.619 57.9705C220.216 56.3737 221.549 54.5614 222.59 52.6026C224.214 48.7017 225.285 44.4699 225.231 39.5608V35.4158L209.927 27.1257L194.622 35.4158V39.5608C194.43 44.3097 195.529 48.5901 197.28 52.6329Z"
            fill="#93F7F9"
          />
          <path
            d="M197.28 52.6329C198.318 54.5806 199.646 56.3818 201.234 57.9705L208.8 65.5353C209.422 66.1582 210.431 66.1582 211.054 65.5353L218.619 57.9705C220.216 56.3737 221.549 54.5614 222.59 52.6026C224.214 48.7017 225.285 44.4699 225.231 39.5608V35.4158L209.927 27.1257L194.622 35.4158V39.5608C194.43 44.3097 195.529 48.5901 197.28 52.6329Z"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M220.449 39.5605V38.6041L209.927 32.8837L199.405 38.2853V39.5605C199.651 45.0732 200.174 50.2031 202.673 52.6026C203.251 53.4189 203.9 54.1909 204.617 54.9076L209.476 59.7667C209.725 60.0149 210.128 60.0149 210.378 59.7667L215.237 54.9076C215.954 54.1901 216.603 53.4223 217.179 52.6128C219.676 48.769 220.948 44.4826 220.449 39.5605Z"
            fill="white"
          />
          <path
            d="M220.449 39.5605V38.6041L209.927 32.8837L199.405 38.2853V39.5605C199.651 45.0732 200.174 50.2031 202.673 52.6026C203.251 53.4189 203.9 54.1909 204.617 54.9076L209.476 59.7667C209.725 60.0149 210.128 60.0149 210.378 59.7667L215.237 54.9076C215.954 54.1901 216.603 53.4223 217.179 52.6128C219.676 48.769 220.948 44.4826 220.449 39.5605Z"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M209.917 44.0645C210.94 44.0645 211.777 44.9016 211.777 45.9249V51.2715C211.777 52.2948 210.94 53.1319 209.917 53.1319C208.894 53.1319 208.056 52.2948 208.056 51.2715V45.9249C208.056 44.9016 208.894 44.0645 209.917 44.0645Z"
            fill="white"
          />
          <path
            d="M209.917 44.0645C210.94 44.0645 211.777 44.9016 211.777 45.9249V51.2715C211.777 52.2948 210.94 53.1319 209.917 53.1319C208.894 53.1319 208.056 52.2948 208.056 51.2715V45.9249C208.056 44.9016 208.894 44.0645 209.917 44.0645Z"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M209.917 37.8852C210.94 37.8852 211.777 38.7227 211.777 39.7457C211.777 40.7693 210.94 41.6061 209.917 41.6061C208.894 41.6061 208.056 40.7693 208.056 39.7457C208.056 38.7227 208.894 37.8852 209.917 37.8852Z"
            fill="white"
          />
          <path
            d="M209.917 37.8852C210.94 37.8852 211.777 38.7227 211.777 39.7457C211.777 40.7693 210.94 41.6061 209.917 41.6061C208.894 41.6061 208.056 40.7693 208.056 39.7457C208.056 38.7227 208.894 37.8852 209.917 37.8852Z"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M166.427 65.732C159.184 81.4189 143.312 92.3041 124.897 92.3041C106.481 92.3041 90.6068 81.4193 83.3606 65.7329"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M175.776 28.0434C182.39 15.7346 195.389 7.36696 210.339 7.36696C225.29 7.36696 238.289 15.7346 244.903 28.0434"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.857666 46.5815C0.857666 68.2392 18.4151 85.7967 40.0732 85.7967C61.7317 85.7967 79.2884 68.2392 79.2884 46.5815C79.2884 46.5815 79.2862 45.1827 79.319 44.492C80.4649 20.2116 100.437 0.857697 124.991 0.857697C149.37 0.857697 169.297 19.935 170.645 43.9759C170.664 44.3191 170.696 46.5815 170.696 46.5815C170.696 68.2392 188.252 85.7967 209.911 85.7967C231.568 85.7967 249.126 68.2392 249.126 46.5815"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.50977 28.043C12.1232 15.7347 25.1226 7.36696 40.0735 7.36696C55.0243 7.36696 68.0238 15.7347 74.6372 28.043"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M90.7561 46.5815C90.7561 27.6737 106.084 12.3458 124.992 12.3458C143.9 12.3458 159.228 27.6737 159.228 46.5815C159.228 65.4893 143.9 80.8172 124.992 80.8172C106.084 80.8172 90.7561 65.4893 90.7561 46.5815Z"
            fill="#39286B"
            fillOpacity="0.3"
          />
          <path
            d="M12.062 46.5819C12.062 31.1117 24.603 18.5704 40.0731 18.5704C55.5432 18.5704 68.0841 31.1117 68.0841 46.5819C68.0841 62.0512 55.5432 74.5925 40.0731 74.5925C24.603 74.5925 12.062 62.0512 12.062 46.5819Z"
            fill="#B8EEFF"
          />
          <path
            d="M112.2 36.9311V33.6263C112.2 27.6337 117.075 22.7587 123.068 22.7587C129.059 22.7587 133.934 27.6337 133.934 33.6263V36.9311"
            fill="#FFD766"
          />
          <path
            d="M112.2 36.9311V33.6263C112.2 27.6337 117.075 22.7587 123.068 22.7587C129.059 22.7587 133.934 27.6337 133.934 33.6263V36.9311"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M130.289 36.9317V33.8079C130.289 29.8929 126.981 26.5861 123.067 26.5861C119.152 26.5861 115.844 29.8929 115.844 33.8079V36.9317"
            fill="white"
          />
          <path
            d="M130.289 36.9317V33.8079C130.289 29.8929 126.981 26.5861 123.067 26.5861C119.152 26.5861 115.844 29.8929 115.844 33.8079V36.9317"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M130.131 67.9322H108.548C107.464 67.9322 106.585 67.0537 106.585 65.97V38.893C106.585 37.8097 107.464 36.9312 108.548 36.9312H137.195C138.278 36.9312 139.156 37.8097 139.156 38.893V49.684"
            fill="#FFAC88"
          />
          <path
            d="M130.131 67.9322H108.548C107.464 67.9322 106.585 67.0537 106.585 65.97V38.893C106.585 37.8097 107.464 36.9312 108.548 36.9312H137.195C138.278 36.9312 139.156 37.8097 139.156 38.893V49.684"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M127.776 45.5646C127.776 48.1647 125.668 50.2734 123.068 50.2734C120.466 50.2734 118.358 48.1647 118.358 45.5646C118.358 42.9637 120.466 40.8556 123.068 40.8556C125.668 40.8556 127.776 42.9637 127.776 45.5646Z"
            fill="white"
          />
          <path
            d="M127.776 45.5646C127.776 48.1647 125.668 50.2734 123.068 50.2734C120.466 50.2734 118.358 48.1647 118.358 45.5646C118.358 42.9637 120.466 40.8556 123.068 40.8556C125.668 40.8556 127.776 42.9637 127.776 45.5646Z"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M127.384 54.9825C126.105 54.3276 124.637 53.8047 123.067 53.8047C117.894 53.8047 113.649 58.0506 113.649 63.223H126.991"
            fill="white"
          />
          <path
            d="M127.384 54.9825C126.105 54.3276 124.637 53.8047 123.067 53.8047C117.894 53.8047 113.649 58.0506 113.649 63.223H126.991"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125.849 59.5657C125.849 65.5514 130.701 70.4041 136.687 70.4041C142.673 70.4041 147.525 65.5514 147.525 59.5657C147.525 53.5799 142.673 48.7277 136.687 48.7277C130.701 48.7277 125.849 53.5799 125.849 59.5657Z"
            fill="white"
          />
          <path
            d="M125.849 59.5657C125.849 65.5514 130.701 70.4041 136.687 70.4041C142.673 70.4041 147.525 65.5514 147.525 59.5657C147.525 53.5799 142.673 48.7277 136.687 48.7277C130.701 48.7277 125.849 53.5799 125.849 59.5657Z"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M146.84 51.9C145.927 50.9871 144.449 50.9871 143.536 51.9L136.248 59.1871L133.542 56.481C132.629 55.5681 131.15 55.5681 130.237 56.481C129.324 57.3931 129.324 58.8724 130.237 59.7854L134.397 63.9461C134.905 64.4533 135.586 64.6683 136.248 64.6117C136.911 64.6683 137.592 64.4533 138.1 63.9461L146.84 55.2048C147.753 54.2923 147.753 52.813 146.84 51.9Z"
            fill="#FFD766"
          />
          <path
            d="M146.84 51.9C145.927 50.9871 144.449 50.9871 143.536 51.9L136.248 59.1871L133.542 56.481C132.629 55.5681 131.15 55.5681 130.237 56.481C129.324 57.3931 129.324 58.8724 130.237 59.7854L134.397 63.9461C134.905 64.4533 135.586 64.6683 136.248 64.6117C136.911 64.6683 137.592 64.4533 138.1 63.9461L146.84 55.2048C147.753 54.2923 147.753 52.813 146.84 51.9Z"
            stroke="#133487"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.1628 62.7389L28.4449 30.1852L52.0097 30.385L51.7265 63.0851"
            fill="white"
          />
          <path
            d="M28.1628 62.7389L28.4449 30.1852L52.0097 30.385L51.7265 63.0851"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.7262 63.0851L51.7113 64.7773C51.6976 66.392 50.3776 67.6891 48.7629 67.6754L31.0449 67.5221C29.4305 67.5085 28.1331 66.1885 28.1468 64.5738L28.1628 62.7393"
            fill="#FFC9A1"
          />
          <path
            d="M51.7262 63.0851L51.7113 64.7773C51.6976 66.392 50.3776 67.6891 48.7629 67.6754L31.0449 67.5221C29.4305 67.5085 28.1331 66.1885 28.1468 64.5738L28.1628 62.7393"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.4607 28.3625C28.4747 26.7477 29.7951 25.4499 31.4099 25.4643L49.1274 25.6177C50.7422 25.6313 52.0396 26.952 52.0256 28.5659L52.0098 30.3841L28.4451 30.1849L28.4607 28.3625Z"
            fill="#FFC9A1"
          />
          <path
            d="M28.4607 28.3625C28.4747 26.7477 29.7951 25.4499 31.4099 25.4643L49.1274 25.6177C50.7422 25.6313 52.0396 26.952 52.0256 28.5659L52.0098 30.3841L28.4451 30.1849L28.4607 28.3625Z"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6785 30.1852L51.7419 30.385"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.4915 63.0851L28.3953 62.8854"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.3221 54.6691C46.3221 55.3849 45.7358 55.9704 45.0208 55.9704H35.1525C34.437 55.9704 33.8513 55.3849 33.8513 54.6691"
            fill="white"
          />
          <path
            d="M46.3221 54.6691C46.3221 55.3849 45.7358 55.9704 45.0208 55.9704H35.1525C34.437 55.9704 33.8513 55.3849 33.8513 54.6691"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.8511 44.802C33.8511 44.0858 34.4367 43.5007 35.1523 43.5007H45.0208C45.7361 43.5007 46.3212 44.0858 46.3212 44.802"
            fill="white"
          />
          <path
            d="M33.8511 44.802C33.8511 44.0858 34.4367 43.5007 35.1523 43.5007H45.0208C45.7361 43.5007 46.3212 44.0858 46.3212 44.802"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.1418 43.5003V40.2441C37.1418 38.6252 38.467 37.3 40.0866 37.3C41.7061 37.3 43.031 38.6252 43.031 40.2441V43.5003"
            stroke="#21038C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.2041 46.6215L36.4646 48.8818"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.4644 46.6688L34.2039 48.9295"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.0708 46.6215L41.3313 48.8818"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.3312 46.6688L39.0708 48.9295"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.9375 46.6215L46.1978 48.8818"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.1981 46.6688L43.937 48.9295"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.2043 50.7418L36.4648 53.002"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.4646 50.7888L34.2041 53.05"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.071 50.7418L41.3318 53.002"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.3313 50.7888L39.071 53.05"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.938 50.7418L46.1983 53.002"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.1982 50.7888L43.9375 53.05"
            stroke="#21038C"
            strokeWidth="0.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Wrapper>
  );
}
