import { useCallback } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { useRiskReport } from "context/risk-report";
import { downloadPdf } from "helpers/download-pdf";
import { SectionContent } from "../../styles";
import { ButtonContainer } from "./styles";

export function ShareSection() {
  const { reportPdf } = useRiskReport();

  const handleDownloadClick = useCallback(() => {
    downloadPdf({ reportPdf });
  }, [reportPdf]);

  return (
    <SectionContent>
      <Typography
        theme="default"
        variant="body1"
        component="h3"
        color="black"
      >
        Download your cancer risk results and share them with your doctor to discuss tailored prevention strategies, early detection plans, and potential treatment options.
      </Typography>
      <ButtonContainer>
        <Button
          fullWidth={true}
          size="large"
          theme="default"
          variant="outlined"
          onClick={handleDownloadClick}
          startIcon={<GetAppIcon color="primary" />}
        >
          Download
        </Button>
      </ButtonContainer>
    </SectionContent>
  );
}
