import { useState, useCallback, useEffect, useRef } from "react";
import { z } from "zod";
import { useHistory, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { Notification } from "storybook/components/notification";
import { useForm } from "storybook/utils/form";
import { usePatientAuth } from "context/patient-auth";
import {
  Container,
  BackContainer,
  ButtonSeparator,
  ButtonsContainer,
  ProgressContainer,
} from "pages/styles/login";
import {
  VerifyButton,
  CodeContainer,
  StyledHeading,
  BannerContainer,
  PasswordlessLink,
  SentConfirmationText,
} from "./styles";

const codeSchema = z.object({
  code: z.string().length(6).regex(/^\d+$/),
});

type Code = z.infer<typeof codeSchema>;

export default function PasswordlessCode() {
  const history = useHistory();
  const inputComponentRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showResentMsg, setShowResentMsg] = useState(false);
  const {
    isEmailSent,
    isEmailResent,
    emailAddress,
    emailLinkError,
    verifyLoginCode,
    sendEmailWithCode,
    setEmailLinkError,
    setEmailPasswordError,
  } = usePatientAuth();

  const [postObj] = useState<Code>({
    code: "",
  });

  const { Form, Input, watch, getFieldState, setValue } = useForm<Code>({
    schema: codeSchema,
    values: postObj,
    mode: "all",
  });

  const handleCodeSubmit = useCallback(
    (data: Code) => {
      setSubmitting(true);
      setEmailLinkError(undefined);
      setShowResentMsg(false);

      verifyLoginCode(data.code);
    },
    [setEmailLinkError, verifyLoginCode]
  );

  const handleResendCode = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setSubmitting(true);

      setShowResentMsg(false);
      setEmailLinkError(undefined);
      sendEmailWithCode(emailAddress, true);
    },
    [emailAddress, sendEmailWithCode, setEmailLinkError]
  );

  const handleBackClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      // User is going back to the main passwordless page, so clear the error state.
      setEmailLinkError(undefined);

      history.push({
        pathname: "/login/passwordless",
        search: location.search,
      });
    },
    [history, setEmailLinkError]
  );

  const handleUsePasswordClick = useCallback(() => {
    setEmailPasswordError(undefined);
    setEmailLinkError(undefined);

    history.push({
      pathname: "/login/password",
      search: location.search,
    });
  }, [history, setEmailLinkError, setEmailPasswordError]);

  const code = watch("code");
  const hasError = Boolean(getFieldState("code").error);
  const verifyButtonDisabled = isSubmitting || hasError || !code;

  useEffect(() => {
    if (isEmailSent || emailLinkError) {
      // Reset code and submission state
      setValue("code", "");
      setSubmitting(false);
      setShowResentMsg(false);

      const inputElement = inputComponentRef.current?.querySelector("input");
      inputElement?.focus();
    }
  }, [emailLinkError, isEmailSent, setValue, setSubmitting]);

  useEffect(() => {
    if (isEmailResent) {
      setShowResentMsg(true);
      setSubmitting(false);
    }
  }, [isEmailResent]);

  if (!emailAddress) {
    return <Redirect to="/login/passwordless" />;
  }

  return (
    <Container>
      <StyledHeading>
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Check your email
        </Typography>
      </StyledHeading>
      {showResentMsg && !emailLinkError && (
        <BannerContainer>
          <Notification
            theme="default"
            variant="success"
            title="The code has been successfully resent"
            fullWidth={true}
          />
        </BannerContainer>
      )}
      {emailLinkError && (
        <BannerContainer>
          <Notification
            theme="default"
            variant="warning"
            title="The code you entered is invalid or has expired"
            fullWidth={true}
          />
        </BannerContainer>
      )}
      <SentConfirmationText>
        <Typography
          component="p"
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          role="contentinfo"
        >
          An email has been sent to <strong>{emailAddress}</strong> with a
          unique, time-sensitive code.
        </Typography>
        <Typography
          component="p"
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          role="contentinfo"
        >
          Copy the <strong>code</strong> to sign in before it expires in 15
          minutes and check your spam folder if you don't see it right away.
        </Typography>
      </SentConfirmationText>
      <CodeContainer>
        <Form onSubmit={handleCodeSubmit}>
          <Input
            name="code"
            maxLength={6}
            theme="default"
            alignText="left"
            hideDirty={true}
            autoComplete="off"
            labelPosition="top"
            inputMode="numeric"
            hideErrorText={true}
            label="Enter Code Here"
            inputRef={inputComponentRef}
          />
          <ButtonsContainer data-id="ButtonsContainer">
            <VerifyButton
              variant="contained"
              $isSubmitting={isSubmitting}
              disabled={verifyButtonDisabled}
            >
              Verify
            </VerifyButton>
            {isSubmitting && (
              <ProgressContainer>
                <CircularProgress size={30} />
              </ProgressContainer>
            )}
          </ButtonsContainer>
        </Form>
      </CodeContainer>
      <PasswordlessLink>
        <Typography
          component="p"
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
        >
          Didn't get a code?{" "}
          <a href="#" onClick={handleResendCode}>
            Click to resend
          </a>
        </Typography>
      </PasswordlessLink>
      <ButtonsContainer>
        <ButtonSeparator>
          <Typography
            theme="default"
            variant="subtitle2"
            color="ebonyClay"
            component="span"
          >
            Or
          </Typography>
        </ButtonSeparator>
        <Button
          type="button"
          theme="default"
          variant="outlined"
          fullWidth={true}
          disabled={isSubmitting}
          onClick={handleUsePasswordClick}
        >
          Use Password
        </Button>
      </ButtonsContainer>
      <BackContainer>
        <a href="#" onClick={handleBackClick}>
          <ArrowBackIcon />
          Back to Sign in
        </a>
      </BackContainer>
    </Container>
  );
}
