import { useContext } from "react";
import { useAdminContent } from "context/admin-content";
import { KioskAuthContext } from "context/kiosk-auth";
import { KioskResults } from "context/admin-content/types";

enum RiskKey {
  testing = "testing",
  breastMri = "breastMri",
  counseling = "counseling",
  average = "average",
}

// TODO: Do we still need this now that we can fetch risk from the backend?
function getRiskKeyLocalStorage() {
  const testing = localStorage.getItem(
    "ciq-questionnaire.meets_testing_criteria"
  );
  const breastMri = localStorage.getItem(
    "ciq-questionnaire.meets_mri_criteria"
  );
  const counseling = localStorage.getItem(
    "ciq-questionnaire.meets_counseling_criteria"
  );

  if (testing === "true") {
    return RiskKey.testing;
  }
  if (breastMri === "true") {
    return RiskKey.breastMri;
  }
  if (counseling === "true") {
    return RiskKey.counseling;
  }

  return RiskKey.average;
}

export function useRiskResultsKiosk(): KioskResults | null {
  const kioskAuthContext = useContext(KioskAuthContext);
  const adminData = useAdminContent();

  if (!kioskAuthContext) {
    throw new Error("This hook must be used within a KioskAuthProvider");
  }

  if (!adminData?.kioskResults) {
    return null;
  }

  // TODO: useRiskRequest instead?
  const riskKey = getRiskKeyLocalStorage();

  return adminData.kioskResults[riskKey];
}
