export function RibbonIcon() {
  return (
    <svg fill="none" viewBox="0 0 36 57" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.24352 16.0342C8.4023 12.8233 11.5468 7.73432 18.1748 7.72535L18.1408 7.70741C25.6119 7.73432 27.7752 12.7085 29.3717 15.636C29.3753 15.636 29.3788 15.6359 29.3806 15.6342C31.0004 13.1677 32.3207 10.6618 32.9234 8.37471C32.6417 7.9155 32.1072 7.26435 32.1072 7.26435C29.9798 4.82121 25.6137 1.23183 18.2143 1.00043C10.9907 1.13856 6.16544 5.23378 4.10617 7.69485C3.88375 7.96033 3.67567 8.24017 3.47656 8.52359C4.13129 10.8932 5.53582 13.4888 7.23812 16.0306L7.24352 16.0342Z"
        fill="white"
      />
      <path
        d="M7.24352 16.0342C8.4023 12.8233 11.5468 7.73432 18.1748 7.72535L18.1408 7.70741C25.6119 7.73432 27.7752 12.7085 29.3717 15.636C29.3753 15.636 29.3788 15.6359 29.3806 15.6342C31.0004 13.1677 32.3207 10.6618 32.9234 8.37471C32.6417 7.9155 32.1072 7.26434 32.1072 7.26434C29.9798 4.82121 25.6137 1.23183 18.2143 1.00044C10.9907 1.13856 6.16544 5.23378 4.10617 7.69485C3.88375 7.96033 3.67567 8.24017 3.47656 8.52359C4.13129 10.8932 5.53582 13.4888 7.23812 16.0306L7.24352 16.0342Z"
        stroke="#822949"
        strokeWidth="1.18674"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9279 8.36134C30.9574 15.8615 21.2768 25.7212 18.5644 28.8516L11.598 35.5828L10.2011 36.9345C5.38069 41.4183 1 44.1886 1 44.1886L7.8108 55.3379C7.8108 55.3379 12.6113 50.1422 18.217 43.3146L18.2061 43.2965L25.4693 34.8644L27.0707 32.7926C28.7155 30.6701 30.5973 27.9939 31.9236 25.9456C32.812 24.5741 33.8977 22.191 34.5509 20.1264C35.4267 17.3706 35.4774 14.3959 34.6143 11.6346L32.9279 8.36134Z"
        fill="#F47FC1"
      />
      <path
        d="M32.9279 8.36134C30.9574 15.8615 21.2768 25.7212 18.5644 28.8516L11.598 35.5828L10.2011 36.9345C5.38069 41.4183 1 44.1886 1 44.1886L7.8108 55.3379C7.8108 55.3379 12.6113 50.1422 18.217 43.3146L18.2061 43.2965L25.4693 34.8644L27.0707 32.7926C28.7155 30.6701 30.5973 27.9939 31.9236 25.9456C32.812 24.5741 33.8977 22.191 34.5509 20.1264C35.4267 17.3706 35.4774 14.3959 34.6143 11.6346L32.9279 8.36134Z"
        stroke="#822949"
        strokeWidth="1.18674"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.42882 8.51717C5.40032 16.0179 15.0805 25.8754 17.7918 29.0062L24.7589 35.7383L26.1553 37.0894C30.9755 41.5713 35.3562 44.3441 35.3562 44.3441L28.5464 55.4932C28.5464 55.4932 23.7461 50.2975 18.1409 43.4687L18.1517 43.4524L10.8861 35.0202L9.28724 32.9456C7.64132 30.8258 5.76026 28.1507 4.43448 26.1014C3.54459 24.7286 2.45936 22.3466 1.8046 20.2828C0.930998 17.5245 0.878545 14.5528 1.7413 11.7909L3.42882 8.51717Z"
        fill="#F47FC1"
      />
      <path
        d="M3.42882 8.51717C5.40032 16.0179 15.0805 25.8754 17.7918 29.0062L24.7589 35.7383L26.1553 37.0894C30.9755 41.5713 35.3562 44.3441 35.3562 44.3441L28.5464 55.4932C28.5464 55.4932 23.7461 50.2975 18.1409 43.4687L18.1517 43.4524L10.8861 35.0202L9.28724 32.9456C7.64132 30.8258 5.76026 28.1507 4.43448 26.1014C3.54459 24.7286 2.45936 22.3466 1.8046 20.2828C0.930998 17.5245 0.878545 14.5528 1.7413 11.7909L3.42882 8.51717Z"
        stroke="#822949"
        strokeWidth="1.18674"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
