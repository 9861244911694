import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { mobile, tablet, desktop } from "styles/breakpoints";

const { colors } = defaultTheme;

export const MainContainer = styled.div`
  display: block;
  min-height: 100%;

  @media (min-width: ${desktop}px) {
    display: flex;
    flex-direction: row;
    position: static;
  }
`;

export const ContentContainer = styled.div`
  padding: 20px 16px 0 16px;
  justify-content: center;
  min-width: 320px;
  display: flex;
  flex: 1;

  @media (min-width: ${mobile}px) {
    padding: 14px 20px 0 20px;
  }

  @media (min-width: ${tablet}px) {
    padding: 90px 50px 0 50px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 464px;
  margin: 0 auto;
`;

export const Header = styled.header`
  position: absolute;
  top: 4px;
  left: 10px;
  width: calc(100vw - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${tablet}px) {
    width: calc(100vw - 40px);
  }
`;

export const LogoIconContainer = styled.div`
  svg {
    width: 134.21px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      width: 183px;
      height: 50px;
    }
  }
`;

export const BackIconContainer = styled.div`
  svg {
    width: 50px;
    height: 50px;
  }
`;

export const Content = styled.section``;

export const FooterContainer = styled.footer`
  margin-top: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  text-align: center;
  background-color: white;
  justify-content: center;
  color: ${colors.ciqBrandLight};

  && {
    p {
      font-size: 12px;
    }

    a {
      color: ${colors.ciqBrandLight};
    }
  }
`;

export const BackContainer = styled.div`
  top: 0;
  right: 50px;
  position: absolute;
  margin-top: 50px;

  svg {
    margin-right: 8px;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.5px;
    color: ${colors.ebonyClay};
  }
}
`;

export const GraphicContainer = styled.div`
  margin-top: 53px;
  padding-bottom: 24px;
  flex-direction: row;
  justify-content: center;

  @media (min-width: ${tablet}px) {
    margin-top: 13px;
    padding-bottom: 47px;
  }

  @media (min-width: ${tablet}px) {
    display: flex;
  }
`;
