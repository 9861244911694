import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const currentYear = new Date().getFullYear();

export function useDateState(
  name: string,
  dayName: string,
  monthName: string,
  yearName: string
) {
  const { setValue, getValues } = useFormContext();
  const [date, setDate] = useState({
    day: "",
    month: "",
    year: "",
    formattedValue: getValues(name),
  });

  useEffect(() => {
    const dateString = getValues(name);

    if (dateString) {
      const [y, m, d] = dateString.split("-");
      setDate({ year: y, month: m, day: d, formattedValue: dateString });
      // prepopulate the form with the date values; doesn't seem to work in storybook, but works fine in the actual app
      setValue(yearName, y, { shouldValidate: true });
      setValue(monthName, m, { shouldValidate: true });
      setValue(dayName, d, { shouldValidate: true });
    }
  }, [dayName, getValues, monthName, name, setValue, yearName]);

  const updateDate = useCallback(
    (field: "day" | "month" | "year", value: string) => {
      setDate((prev) => {
        const newDate = { ...prev, [field]: value };

        if (newDate.day && newDate.month && newDate.year) {
          const dateString = `${newDate.year}-${
            newDate.month
          }-${newDate.day.padStart(2, "0")}`;
          setValue(name, dateString, { shouldValidate: true });

          return {
            ...newDate,
            formattedValue: dateString,
          };
        } else {
          setValue(name, "", { shouldValidate: true });

          return newDate;
        }
      });
    },
    [setValue, name]
  );

  const monthError = !date.month;

  const dayError =
    !date.day || parseInt(date.day) < 1 || parseInt(date.day) > 31;

  const yearError =
    !date.year ||
    parseInt(date.year) < 1900 ||
    parseInt(date.year) > currentYear;

  return {
    date,
    updateDate,
    monthError,
    dayError,
    yearError,
  };
}
