import { HeadingContainer, StyledSection, SectionTitle } from "./styles";

type Props = {
  number: number;
  title: string;
};

export function SectionHeading({ number, title }: Props) {
  return (
    <HeadingContainer>
      <StyledSection>
        <SectionTitle variant="h4" component="h2" color="governorBay">
          <span>
            {number}
          </span>
          <span>
            {title}
          </span>
        </SectionTitle>
      </StyledSection>
    </HeadingContainer>
  );
}
