import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  margin-bottom: 100px;

  && {
    button {
      text-transform: none;
    }
  }
`;
