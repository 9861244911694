import { useEffect, useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { format } from "date-fns";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { usePatientAuth } from "context/patient-auth";
import { Loading } from "components/loading";
import { Typography } from "storybook/components/typography";
import { RiskResultsEmptyState } from "components/icons/risk-results-empty-state";
import {
  ReportsList,
  ReportItem,
  ReportLink,
  GraphicContainer,
} from "./styles";

type RiskReport = {
  id: string;
  date: string;
};

export function RiskReportsList() {
  const { logout } = usePatientAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [riskReports, setRiskReports] = useState<RiskReport[] | null>([]);

  useEffect(() => {
    const fetchRiskReports = async () => {
      setIsLoading(true);
      const patientId = localStorage.getItem("ciq_id");
      const idToken = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/risk_reports`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.status === 401) {
          logout();
          return;
        }

        const data = await response.json();

        if (data.reports.length === 0) {
          setRiskReports(null);
        } else {
          setRiskReports(data.reports);
        }
      } catch (error) {
        console.error("Error fetching risk reports: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRiskReports();
  }, [logout]);

  if (isLoading) {
    return <Loading />;
  }

  if (riskReports === null) {
    return (
      <GraphicContainer>
        <RiskResultsEmptyState />
      </GraphicContainer>
    );
  }

  return (
    <div>
      <ReportsList>
        {riskReports.map((report) => (
          <ReportItem key={report.id}>
            <ReportLink to={`/reports/${report.id}`}>
              <div>
                <Typography variant="body1" theme="default" color="black">
                  Screening Results
                </Typography>
                <Typography
                  variant="body2"
                  theme="default"
                  color="ciqBrandDark"
                >
                  Date: {format(new Date(report.date), "MM/dd/yyyy")}
                </Typography>
              </div>
              <ChevronRightIcon />
            </ReportLink>
          </ReportItem>
        ))}
      </ReportsList>
    </div>
  );
}
