import { useCallback, useState } from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { InterestModal } from "components/interest-modal";
import { ModalType, usePatientInterest } from "context/patient-interest";
import { useRiskProfile } from "context/risk-profile";
import { SectionContent } from "../../styles";
import { NurseIcon } from "./nurse-icon";

import {
  Headings,
  WhySchedule,
  ListContainer,
  ButtonContainer,
  GraphicContainer,
} from "./styles";

type Props = {
  html: string;
};

export function NextStepsSection({ html }: Props) {
  const [showModal, setShowModal] = useState(false);
  const { setModalType, interestLevel } = usePatientInterest();
  const { riskData } = useRiskProfile();

  const handleRequestClick = useCallback(() => {
    setModalType(ModalType.INITIAL);
    setShowModal(true);
  }, [setModalType]);

  return (
    <SectionContent>
      <Headings>
        <Typography theme="default" variant="h5" component="h2" color="black">
          To help your doctor, act on your risk results
        </Typography>
      </Headings>
      <GraphicContainer>
        <NurseIcon />
      </GraphicContainer>
      <WhySchedule>
        <Typography
          theme="default"
          variant="body1"
          component="h3"
          color="black"
        >
          Why schedule a genetic counseling appointment?
        </Typography>
      </WhySchedule>
      <ListContainer>{HtmlParser(html)}</ListContainer>
      <ButtonContainer>
        <Button
          size="large"
          theme="default"
          fullWidth={true}
          color="secondary"
          variant="contained"
          onClick={handleRequestClick}
          disabled={interestLevel !== null}
        >
          Request an Appointment
        </Button>
      </ButtonContainer>
      {showModal && <InterestModal riskResults={riskData} />}
    </SectionContent>
  );
}
