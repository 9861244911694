import React from "react";
import type { TooltipProps as MuiTooltipProps } from "@material-ui/core";
import { ComponentThemeProvider } from "storybook/context/component-theme";

import { StyledTooltipDefault } from "./theme/default";

interface BaseTooltipProps extends Pick<MuiTooltipProps, "children" | "title"> {
  // Using Pick to obtain placement does not result in Storybook rendering
  // the placements control as a select. Not sure why, but this approach
  // works.
  placement?: MuiTooltipProps["placement"];
}

export interface DefaultTooltipProps extends BaseTooltipProps {
  theme: "default";
}

export type TooltipProps = DefaultTooltipProps;

const styledTooltipMap = {
  default: StyledTooltipDefault,
} as const;

export function Tooltip({
  theme,
  placement,
  children,
  title,
}: TooltipProps): JSX.Element {
  const StyledTooltip = styledTooltipMap[theme];

  return (
    <ComponentThemeProvider theme={theme}>
      <StyledTooltip
        placement={placement}
        arrow={true}
        interactive={true}
        title={title}
        data-testid="Tooltip"
        enterTouchDelay={0}
        leaveTouchDelay={20000}
      >
        {children}
      </StyledTooltip>
    </ComponentThemeProvider>
  );
}
