export function VirusIcon() {
  return (
    <svg fill="none" viewBox="0 0 45 48" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.3643 32.9785C36.3785 32.096 37.7401 31.6436 39.0576 31.7524C39.7319 31.808 40.3567 32.1011 40.9263 32.4679C41.1725 32.6272 41.4592 32.7343 41.777 32.7703C42.6517 32.8688 43.5098 32.3411 43.8166 31.5177C44.2356 30.402 43.5833 29.2051 42.4624 28.9258C42.1262 28.8418 41.7899 28.8495 41.4775 28.9344C41.3305 28.9737 41.0457 29.1117 41.0457 29.1117C39.3479 29.7466 37.2789 29.206 36.1084 27.8222"
        fill="#FFD576"
      />
      <path
        d="M35.3643 32.9785C36.3785 32.096 37.7401 31.6436 39.0576 31.7524C39.7319 31.808 40.3567 32.1011 40.9263 32.4679C41.1725 32.6272 41.4592 32.7343 41.777 32.7703C42.6517 32.8688 43.5098 32.3411 43.8166 31.5177C44.2356 30.402 43.5833 29.2051 42.4624 28.9258C42.1262 28.8418 41.7899 28.8495 41.4775 28.9344C41.3305 28.9737 41.0457 29.1117 41.0457 29.1117C39.3479 29.7466 37.2789 29.206 36.1084 27.8222"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6586 16.3845C17.3327 16.1668 16.1143 15.4059 15.3597 14.3229C14.9715 13.7677 14.7984 13.1003 14.7194 12.4269C14.6841 12.1363 14.5833 11.8468 14.4085 11.5786C13.9279 10.8427 12.9733 10.5188 12.1414 10.8067C11.0171 11.1964 10.5096 12.461 11.0104 13.5047C11.16 13.8157 11.3801 14.0702 11.644 14.2562C11.77 14.3444 12.0574 14.4755 12.0574 14.4755C13.6287 15.3785 14.5329 17.3167 14.2136 19.1006"
        fill="#FFD576"
      />
      <path
        d="M18.6586 16.3845C17.3327 16.1668 16.1143 15.4059 15.3597 14.3229C14.9715 13.7677 14.7984 13.1003 14.7194 12.4269C14.6841 12.1363 14.5833 11.8468 14.4085 11.5786C13.9279 10.8427 12.9733 10.5188 12.1414 10.8067C11.0171 11.1964 10.5096 12.461 11.0104 13.5047C11.16 13.8157 11.3801 14.0702 11.644 14.2562C11.77 14.3444 12.0574 14.4755 12.0574 14.4755C13.6287 15.3785 14.5329 17.3167 14.2136 19.1006"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8574 37.9991C28.5369 38.7342 29.4265 41.429 29.7212 43.0286C29.8189 43.5606 29.826 44.1022 29.8313 44.6429C29.8437 45.748 30.744 46.7078 31.9532 46.6932C32.786 46.6829 33.5513 46.1449 33.8443 45.3634C34.1568 44.534 33.9171 43.6789 33.356 43.1314C33.356 43.1314 29.414 38.6023 29.8988 35.8991"
        fill="#FFD576"
      />
      <path
        d="M25.8574 37.9991C28.5369 38.7342 29.4265 41.429 29.7212 43.0286C29.8189 43.5606 29.826 44.1022 29.8313 44.6429C29.8437 45.748 30.744 46.7078 31.9532 46.6932C32.786 46.6829 33.5513 46.1449 33.8443 45.3634C34.1568 44.534 33.9171 43.6789 33.356 43.1314C33.356 43.1314 29.414 38.6023 29.8988 35.8991"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8927 39.4238C19.2046 40.8221 18.7256 42.2128 17.7351 43.2418C17.481 43.5596 17.3088 43.9504 17.2594 44.3848C17.1162 45.6135 17.9976 46.7247 19.2251 46.8677C20.456 47.0101 21.5659 46.1292 21.7091 44.9007C21.7398 44.6349 21.7227 44.3754 21.6648 44.1294C21.2624 42.5229 21.5454 41.1092 22.6195 39.9722"
        fill="#FFD576"
      />
      <path
        d="M18.8927 39.4238C19.2046 40.8221 18.7256 42.2128 17.7351 43.2418C17.481 43.5596 17.3088 43.9504 17.2594 44.3848C17.1162 45.6135 17.9976 46.7247 19.2251 46.8677C20.456 47.0101 21.5659 46.1292 21.7091 44.9007C21.7398 44.6349 21.7227 44.3754 21.6648 44.1294C21.2624 42.5229 21.5454 41.1092 22.6195 39.9722"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6807 30.7873C38.521 23.418 34.0384 15.9525 26.6701 14.113C19.3 12.2734 11.8345 16.7553 9.99599 24.124C8.15572 31.4942 12.6383 38.9587 20.0066 40.7992C27.3767 42.6387 34.8423 38.1567 36.6807 30.7873Z"
        fill="#FFD576"
      />
      <path
        d="M36.6807 30.7873C38.521 23.418 34.0384 15.9525 26.6701 14.113C19.3 12.2734 11.8345 16.7553 9.99599 24.124C8.15572 31.4942 12.6383 38.9587 20.0066 40.7992C27.3767 42.6387 34.8423 38.1567 36.6807 30.7873Z"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0752 32.9765C23.8971 33.6885 24.0245 34.4048 24.3738 34.9874C24.7249 35.5709 25.296 36.0199 26.0086 36.1972C26.7212 36.3755 27.4372 36.2478 28.0205 35.8982C28.6021 35.5478 29.051 34.9753 29.2291 34.2635C29.4073 33.5514 29.2798 32.8352 28.9305 32.2526C28.5794 31.6699 28.0066 31.22 27.2957 31.0419"
        fill="#FFD576"
      />
      <path
        d="M24.0752 32.9765C23.8971 33.6885 24.0245 34.4048 24.3738 34.9874C24.7249 35.5709 25.296 36.0199 26.0086 36.1972C26.7212 36.3755 27.4372 36.2478 28.0205 35.8982C28.6021 35.5478 29.051 34.9753 29.2291 34.2635C29.4073 33.5514 29.2798 32.8352 28.9305 32.2526C28.5794 31.6699 28.0066 31.22 27.2957 31.0419"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9929 21.3802C20.4682 21.249 19.9417 21.3434 19.5124 21.6012C19.0814 21.8591 18.7509 22.2807 18.6197 22.8051C18.4903 23.3293 18.5823 23.8573 18.8412 24.2864C19.0985 24.7157 19.521 25.0464 20.044 25.1776C20.5687 25.3086 21.0968 25.2144 21.5261 24.9564C21.9554 24.6994 22.2859 24.277 22.4171 23.7526"
        fill="#FFD576"
      />
      <path
        d="M20.9929 21.3802C20.4682 21.249 19.9417 21.3434 19.5124 21.6012C19.0814 21.8591 18.7509 22.2807 18.6197 22.8051C18.4903 23.3293 18.5823 23.8573 18.8412 24.2864C19.0985 24.7157 19.521 25.0464 20.044 25.1776C20.5687 25.3086 21.0968 25.2144 21.5261 24.9564C21.9554 24.6994 22.2859 24.277 22.4171 23.7526"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0015 17.0137C24.7675 15.4732 25.0449 14.007 25.8336 12.7099C26 12.4374 26.2028 12.1873 26.4264 11.9602C26.8442 11.5352 27.1267 10.963 27.1822 10.2971C27.2775 9.13869 26.5703 8.02911 25.4834 7.61708C23.9667 7.0421 22.3338 7.92718 21.9542 9.45399C21.819 9.98789 21.8588 10.5251 22.0322 11.0058C22.1864 11.4265 22.3182 11.8565 22.3581 12.3029C22.5002 13.8675 22.1448 15.6255 21.2227 16.8029"
        fill="#FFD576"
      />
      <path
        d="M25.0015 17.0137C24.7675 15.4732 25.0449 14.007 25.8336 12.7099C26 12.4374 26.2028 12.1873 26.4264 11.9602C26.8442 11.5352 27.1267 10.963 27.1822 10.2971C27.2775 9.13869 26.5703 8.02911 25.4834 7.61708C23.9667 7.0421 22.3338 7.92718 21.9542 9.45399C21.819 9.98789 21.8588 10.5251 22.0322 11.0058C22.1864 11.4265 22.3182 11.8565 22.3581 12.3029C22.5002 13.8675 22.1448 15.6255 21.2227 16.8029"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5786 25.0459L33.6023 24.9449C33.947 23.5671 34.7147 22.1267 35.6193 21.0327C36.1372 20.4097 36.7609 19.8254 37.463 19.4767C37.9572 19.2316 38.5153 19.1786 39.066 19.2231C40.5067 19.338 41.849 18.2609 41.931 16.6869C41.9876 15.6339 41.3803 14.6219 40.4247 14.1757C38.8527 13.4431 37.0965 14.3376 36.6989 15.9304C36.6314 16.2122 36.584 16.3871 36.4071 16.6723C35.9402 17.4246 35.3257 18.1187 34.6764 18.6944C33.6388 19.6155 32.3002 20.1922 30.916 20.3138"
        fill="#FFD576"
      />
      <path
        d="M33.5786 25.0459L33.6023 24.9449C33.947 23.5671 34.7147 22.1267 35.6193 21.0327C36.1372 20.4097 36.7609 19.8254 37.463 19.4767C37.9572 19.2316 38.5153 19.1786 39.066 19.2231C40.5067 19.338 41.849 18.2609 41.931 16.6869C41.9876 15.6339 41.3803 14.6219 40.4247 14.1757C38.8527 13.4431 37.0965 14.3376 36.6989 15.9304C36.6314 16.2122 36.584 16.3871 36.4071 16.6723C35.9402 17.4246 35.3257 18.1187 34.6764 18.6944C33.6388 19.6155 32.3002 20.1922 30.916 20.3138"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7375 30.5216C12.531 32.1169 11.67 33.606 10.4044 34.5546C9.75493 35.0422 8.96392 35.2751 8.16125 35.3985C7.81487 35.4517 7.47183 35.5844 7.15875 35.8047C6.2978 36.4104 5.94809 37.5663 6.32611 38.5491C6.83902 39.8797 8.37274 40.4341 9.60172 39.7931C9.96809 39.6012 10.2628 39.327 10.476 39.0031C10.5759 38.8499 10.7208 38.501 10.7208 38.501C11.7383 36.5826 14.0214 35.4225 16.1696 35.7318"
        fill="#FFD576"
      />
      <path
        d="M12.7375 30.5216C12.531 32.1169 11.67 33.606 10.4044 34.5546C9.75493 35.0422 8.96392 35.2751 8.16125 35.3985C7.81487 35.4517 7.47183 35.5844 7.15875 35.8047C6.2978 36.4104 5.94809 37.5663 6.32611 38.5491C6.83902 39.8797 8.37274 40.4341 9.60172 39.7931C9.96809 39.6012 10.2628 39.327 10.476 39.0031C10.5759 38.8499 10.7208 38.501 10.7208 38.501C11.7383 36.5826 14.0214 35.4225 16.1696 35.7318"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1519 21.4933C10.7426 21.7563 9.37105 21.2277 8.37846 20.2013C8.07002 19.9357 7.68448 19.7497 7.25299 19.6847C6.02907 19.4986 4.88719 20.3383 4.7018 21.5619C4.51476 22.7845 5.3564 23.9276 6.57868 24.1134C6.84282 24.1536 7.10204 24.1461 7.34978 24.0972C8.97073 23.7518 10.3735 24.0852 11.4711 25.1982"
        fill="#FFD576"
      />
      <path
        d="M12.1519 21.4933C10.7426 21.7563 9.37105 21.2277 8.37846 20.2013C8.07002 19.9357 7.68448 19.7497 7.25299 19.6847C6.02907 19.4986 4.88719 20.3383 4.7018 21.5619C4.51476 22.7845 5.3564 23.9276 6.57868 24.1134C6.84282 24.1536 7.10204 24.1461 7.34978 24.0972C8.97073 23.7518 10.3735 24.0852 11.4711 25.1982"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.29666 30.3606C5.58793 29.1929 4.87736 28.0095 3.70909 27.7183C2.54082 27.426 1.35656 28.1363 1.06529 29.305C0.774024 30.4728 1.48458 31.6569 2.65285 31.9484C3.82112 32.2396 5.00379 31.5292 5.29666 30.3606Z"
        fill="#FFD576"
      />
      <path
        d="M5.29666 30.3606C5.58793 29.1929 4.87736 28.0095 3.70909 27.7183C2.54082 27.426 1.35656 28.1363 1.06529 29.305C0.774024 30.4728 1.48458 31.6569 2.65285 31.9484C3.82112 32.2396 5.00379 31.5292 5.29666 30.3606Z"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6186 4.45666C14.98 3.01089 14.1013 1.5469 12.6555 1.18711C11.2097 0.825669 9.74573 1.7044 9.38429 3.15016C9.0245 4.59427 9.90324 6.05993 11.349 6.42138C12.7948 6.78116 14.2571 5.90243 14.6186 4.45666Z"
        fill="#FFD576"
      />
      <path
        d="M14.6186 4.45666C14.98 3.01089 14.1013 1.5469 12.6555 1.18711C11.2097 0.825669 9.74573 1.7044 9.38429 3.15016C9.0245 4.59427 9.90324 6.05993 11.349 6.42138C12.7948 6.78116 14.2571 5.90243 14.6186 4.45666Z"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.2045 8.86085C38.5451 7.49252 37.7124 6.10689 36.3442 5.76513C34.9761 5.42318 33.5917 6.25525 33.2492 7.62358C32.9085 8.9919 33.7395 10.3773 35.1077 10.7191C36.4758 11.061 37.862 10.229 38.2045 8.86085Z"
        fill="#FFD576"
      />
      <path
        d="M38.2045 8.86085C38.5451 7.49252 37.7124 6.10689 36.3442 5.76513C34.9761 5.42318 33.5917 6.25525 33.2492 7.62358C32.9085 8.9919 33.7395 10.3773 35.1077 10.7191C36.4758 11.061 37.862 10.229 38.2045 8.86085Z"
        stroke="#822949"
        strokeWidth="1.0246"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
