import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Button } from "storybook/components/button";
import { Loading } from "components/loading";
import { useAdminContent } from "context/admin-content";
import { useRiskReport } from "context/risk-report";
import { useRiskProfile } from "context/risk-profile";
import { SectionHeading } from "./section-heading";
import { RiskResultsSection } from "./sections/risk-results";
import { NextStepsSection } from "./sections/next-steps";
import { LearnMoreSection } from "./sections/learn-more";
import { Content } from "../styles";
import { ButtonContainer, GlobalStyle, PageHeading } from "./styles";
import { ShareSection } from "./sections/share";

export function PatientResultsDisplay2() {
  const adminData = useAdminContent();
  const { riskData } = useRiskProfile();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { isLoading: reportLoading } = useRiskReport();
  const pageRef = useRef<HTMLHeadingElement | null>(null);

  const isLoading = adminData?.isLoading || reportLoading || !riskData;

  const handleBackToTopClick = useCallback(() => {
    pageRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.document.body.clientHeight / 3 <= window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  const sections = [
    {
      title: "Risk Results",
      content: <RiskResultsSection />,
    },
    ...(riskData.videoEnabled
      ? [
          {
            title: "Learn More",
            content: <LearnMoreSection videoUrl={riskData.videoUrl} />,
          },
        ]
      : []),
    ...(riskData.showScheduler
      ? [
          {
            title: "Next Steps",
            content: <NextStepsSection html={riskData.bodyHtml} />,
          },
        ]
      : []),
    { title: "Share", content: <ShareSection /> },
  ];

  return (
    <>
      <GlobalStyle />
      <PageHeading ref={pageRef}>Patient Results Page</PageHeading>
      {sections.map((section, index) => (
        <React.Fragment key={section.title}>
          <SectionHeading number={index + 1} title={section.title} />
          {section.content}
        </React.Fragment>
      ))}
      <ButtonContainer $show={showScrollButton}>
        <Button
          theme="default"
          variant="text"
          color="inherit"
          onClick={handleBackToTopClick}
          startIcon={<ArrowUpwardIcon color="primary" />}
        >
          Back to top
        </Button>
      </ButtonContainer>
    </>
  );
}
