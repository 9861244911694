import { ComponentType } from "react";
import { useTabContext } from "storybook/components/tabs";
import { futureTheme } from "storybook/themes/future";

const { activeTabMobileText, black } = futureTheme.colors;

type Props = {
  tabId: string;
  component: ComponentType<{ fill: string }>;
};

export function TabIcon({ tabId, component: Component }: Props) {
  const { activeTabId } = useTabContext();

  const isActive = activeTabId === tabId;
  const fill = isActive ? activeTabMobileText : black;

  return <Component fill={fill} />;
}
