export function AppointmentIcon() {
  return (
    <svg fill="none" viewBox="0 0 41 45" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3827 5.3103C15.2793 5.06273 15.2246 4.80224 15.2246 4.53279C15.2246 2.87637 17.3016 1.53414 19.8638 1.53414C22.426 1.53414 24.5029 2.87637 24.5029 4.53279C24.5029 4.89271 24.495 5.05876 24.315 5.37891"
        fill="#FFD576"
      />
      <path
        d="M15.3827 5.3103C15.2793 5.06273 15.2246 4.80224 15.2246 4.53279C15.2246 2.87637 17.3016 1.53414 19.8638 1.53414C22.426 1.53414 24.5029 2.87637 24.5029 4.53279C24.5029 4.89271 24.495 5.05876 24.315 5.37891"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 44H39.6503L36.5312 33.9493C36.1286 32.3104 34.8522 31.032 33.2143 30.6157C30.8574 30.0171 28.8364 29.5587 26.7225 28.9494C25.5969 28.6241 24.8259 27.5946 24.8259 26.4259V23.9994L25.1492 23.636C26.0083 22.675 26.5677 21.4838 26.7558 20.2093C26.7558 20.2093 27.6178 20.2172 27.7432 19.4354C28.1282 17.0481 28.4064 16.9453 28.4064 15.8951C28.4064 14.8479 28.0165 14.5981 27.5483 14.8695C27.6266 14.6011 27.7648 14.0408 27.7873 13.262C27.947 7.61945 24.2254 3.92144 19.8642 3.92144C15.503 3.92144 11.7815 7.61945 11.9412 13.262C11.9637 14.0408 12.1019 14.6011 12.1802 14.8695C11.711 14.5981 11.3221 14.8479 11.3221 15.8951C11.3221 16.9453 11.6003 17.0481 11.9853 19.4354C12.1107 20.2172 12.9727 20.2093 12.9727 20.2093C13.1608 21.4838 13.7192 22.675 14.5793 23.636L14.9025 23.9994V26.4259C14.9025 27.5946 14.1316 28.6241 13.006 28.9494C10.8921 29.5587 9.41579 30.1915 7.05984 30.79C5.42096 31.2054 4.14453 32.4848 3.74192 34.1227L1.07151 43.9069"
        fill="white"
      />
      <path
        d="M1 44H39.6503L36.5312 33.9493C36.1286 32.3104 34.8522 31.032 33.2143 30.6157C30.8574 30.0171 28.8364 29.5587 26.7225 28.9494C25.5969 28.6241 24.8259 27.5946 24.8259 26.4259V23.9994L25.1492 23.636C26.0083 22.675 26.5677 21.4838 26.7558 20.2093C26.7558 20.2093 27.6178 20.2172 27.7432 19.4354C28.1282 17.0481 28.4064 16.9453 28.4064 15.8951C28.4064 14.848 28.0165 14.5981 27.5483 14.8695C27.6266 14.6011 27.7648 14.0408 27.7873 13.262C27.947 7.61945 24.2254 3.92144 19.8642 3.92144C15.503 3.92144 11.7815 7.61945 11.9412 13.262C11.9637 14.0408 12.1019 14.6011 12.1802 14.8695C11.711 14.5981 11.3221 14.848 11.3221 15.8951C11.3221 16.9453 11.6003 17.0481 11.9853 19.4354C12.1107 20.2172 12.9727 20.2093 12.9727 20.2093C13.1608 21.4838 13.7192 22.675 14.5793 23.636L14.9025 23.9994V26.4259C14.9025 27.5946 14.1316 28.6241 13.006 28.9494C10.8921 29.5587 9.41579 30.1915 7.05984 30.79C5.42096 31.2054 4.14453 32.4848 3.74192 34.1226L1.07151 43.9069"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9033 24.0057L18.3203 25.8658C19.4884 26.3308 20.7953 26.3043 21.9428 25.7913L24.8263 24.0057"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9121 10.1637C14.9121 10.1637 18.7423 14.9286 25.2825 13.2465L26.1241 14.2744H27.7111"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8994 11.8824L13.8856 15.208L12.0166 13.7132"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3516 37.7646L25.869 28.531L27.711 33.3728L20.3516 37.7646Z"
        fill="#B0B7E4"
      />
      <path
        d="M20.3516 37.7646L25.869 28.531L27.711 33.3728L20.3516 37.7646Z"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3534 37.7656L13.7953 28.5412L12.4854 33.4405L20.3534 37.7656Z"
        fill="#B0B7E4"
      />
      <path
        d="M20.3534 37.7656L13.7953 28.5412L12.4854 33.4405L20.3534 37.7656Z"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7109 29.1979L30.8988 35.9922L27.7109 29.1979Z"
        fill="white"
      />
      <path
        d="M27.7109 29.1979L30.8988 35.9922"
        stroke="#822949"
        strokeWidth="1.27816"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2187 29.1638L10.6426 33.1514L12.2187 29.1638Z"
        fill="white"
      />
      <path
        d="M12.2187 29.1638L10.6426 33.1514"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78027 40.4561C6.27421 35.8456 8.81179 32.537 10.448 33.0668C12.0859 33.5975 12.2017 37.7657 10.7078 42.377"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5445 37.4661C33.5445 38.4958 32.7112 39.3291 31.6815 39.3291C30.6526 39.3291 29.8193 38.4958 29.8193 37.4661C29.8193 36.4373 30.6526 35.604 31.6815 35.604C32.7112 35.604 33.5445 36.4373 33.5445 37.4661Z"
        fill="#B0B7E4"
      />
      <path
        d="M33.5445 37.4661C33.5445 38.4958 32.7112 39.3291 31.6815 39.3291C30.6526 39.3291 29.8193 38.4958 29.8193 37.4661C29.8193 36.4373 30.6526 35.604 31.6815 35.604C32.7112 35.604 33.5445 36.4373 33.5445 37.4661Z"
        stroke="#822949"
        strokeWidth="1.27815"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
