import React, { useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Modal } from "storybook/components/modal";
import { Button } from "storybook/components/button";
import {
  ErrorType,
  ModalType,
  InterestLevel,
  usePatientInterest,
} from "context/patient-interest";
import { ErrorBanner, ActionsWrapper, ModalBodyWrapper } from "./styles";

type Props = {
  riskResults: any;
};

export function InitialModal({ riskResults }: Props) {
  const history = useHistory();

  const { loading, errorType, saveInterest, setModalType } =
    usePatientInterest();

  const handleYesClick = useCallback(async () => {
    if (!riskResults) {
      return;
    }

    if (riskResults.collectAvailability) {
      setModalType(ModalType.EMAIL);
      return;
    }

    await saveInterest({
      level: InterestLevel.INTERESTED,
    });

    if (riskResults.lockPatientHub && riskResults.showPatientHub) {
      setModalType(ModalType.LOCK_PATIENT_HUB);
      return;
    }

    if (riskResults.showPatientHub && !riskResults.collectAvailability) {
      history.push("/institution/patient-hub");
    }
  }, [riskResults, history, saveInterest, setModalType]);

  const handleNeedMoreTime = useCallback(async () => {
    saveInterest({
      level: InterestLevel.NEED_TIME,
    });
  }, [saveInterest]);

  const handleDecline = useCallback(() => {
    setModalType(ModalType.DECLINE);
  }, [setModalType]);

  const isLoading = loading || !riskResults;

  return (
    <>
      <Modal.Body>
        <ModalBodyWrapper>
          {/* TODO: make this nicer - discuss with Product */}
          {errorType === ErrorType.GENERAL && (
            <ErrorBanner>Something went wrong, please try again</ErrorBanner>
          )}
          Thank you for completing the questionnaire. Are you interested in
          moving forward with a specialist?
        </ModalBodyWrapper>
      </Modal.Body>
      <Modal.Actions alignButtons="center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ActionsWrapper>
            <Button
              variant="outlined"
              theme="default"
              fullWidth={true}
              onClick={handleDecline}
              disabled={isLoading}
            >
              No, Decline
            </Button>
            <Button
              variant="contained"
              theme="default"
              color="secondary"
              fullWidth={true}
              onClick={handleNeedMoreTime}
              disabled={isLoading}
            >
              No, Need More Time
            </Button>
            <Button
              variant="contained"
              theme="default"
              color="default"
              fullWidth={true}
              onClick={handleYesClick}
              disabled={isLoading}
            >
              Yes
            </Button>
          </ActionsWrapper>
        )}
      </Modal.Actions>
    </>
  );
}
