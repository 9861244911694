export function SpecialistIcon() {
  return (
    <svg fill="none" viewBox="0 0 45 48" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16568 37.4121H39.7232C41.4713 37.4121 42.8889 35.9949 42.8889 34.2462V8.08775C42.8889 6.34001 41.4713 4.92187 39.7232 4.92187H4.16568C2.41755 4.92187 1 6.34001 1 8.08775V34.2462C1 35.9949 2.41755 37.4121 4.16568 37.4121Z"
        fill="white"
      />
      <path
        d="M4.16568 37.4121H39.7232C41.4713 37.4121 42.8889 35.9949 42.8889 34.2462V8.08775C42.8889 6.34001 41.4713 4.92187 39.7232 4.92187H4.16568C2.41755 4.92187 1 6.34001 1 8.08775V34.2462C1 35.9949 2.41755 37.4121 4.16568 37.4121Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7692 31.543L33.7 24.1894L23.5931 24.6345L19.7803 11.4035"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.479 31.6948C35.3787 30.4203 36.3214 29.3194 37.586 29.2357C38.8507 29.152 39.9591 30.1169 40.0594 31.3904C40.1596 32.665 39.215 33.7668 37.9504 33.8504C36.6858 33.9339 35.5792 32.9692 35.479 31.6948Z"
        fill="#B0B7E4"
      />
      <path
        d="M35.479 31.6948C35.3787 30.4203 36.3214 29.3194 37.586 29.2357C38.8507 29.152 39.9591 30.1169 40.0594 31.3904C40.1596 32.665 39.215 33.7668 37.9504 33.8504C36.6858 33.9339 35.5792 32.9692 35.479 31.6948Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4088 24.3421C31.3079 23.0676 32.2517 21.9666 33.5171 21.8821C34.7824 21.7986 35.888 22.7643 35.9888 24.0378C36.0897 25.3123 35.1459 26.4133 33.8805 26.4968C32.6151 26.5813 31.5096 25.6166 31.4088 24.3421Z"
        fill="#B0B7E4"
      />
      <path
        d="M31.4088 24.3421C31.3079 23.0676 32.2517 21.9666 33.5171 21.8821C34.7824 21.7986 35.888 22.7643 35.9888 24.0378C36.0897 25.3123 35.1459 26.4133 33.8805 26.4968C32.6151 26.5813 31.5096 25.6166 31.4088 24.3421Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3033 24.7866C21.2021 23.5121 22.1464 22.4111 23.4092 22.3275C24.6757 22.2439 25.782 23.2087 25.8832 24.4833C25.9826 25.7567 25.0383 26.8586 23.7737 26.9422C22.5091 27.0258 21.4027 26.061 21.3033 24.7866Z"
        fill="#B0B7E4"
      />
      <path
        d="M21.3033 24.7866C21.2021 23.5121 22.1464 22.4111 23.4092 22.3275C24.6757 22.2439 25.782 23.2087 25.8832 24.4833C25.9826 25.7567 25.0383 26.8586 23.7737 26.9422C22.5091 27.0258 21.4027 26.061 21.3033 24.7866Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4897 11.5559C17.3897 10.2816 18.3348 9.18057 19.5979 9.09606C20.8628 9.01246 21.9714 9.97806 22.0714 11.2517C22.1713 12.5263 21.2281 13.6272 19.9632 13.7107C18.6982 13.7952 17.5914 12.8305 17.4897 11.5559Z"
        fill="#B0B7E4"
      />
      <path
        d="M17.4897 11.5559C17.3897 10.2816 18.3348 9.18057 19.5979 9.09606C20.8628 9.01246 21.9714 9.97806 22.0714 11.2517C22.1713 12.5263 21.2281 13.6272 19.9632 13.7107C18.6982 13.7952 17.5914 12.8305 17.4897 11.5559Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2167 43.0745C21.4044 38.853 23.4023 31.9428 23.4023 31.9428C23.8739 30.9071 23.4352 29.6762 22.4207 29.1929C21.4081 28.7096 20.2054 29.1567 19.732 30.1913L17.193 35.7636H17.1949L17.1839 21.209C17.1821 20.0662 16.2755 19.1397 15.1586 19.1388C14.04 19.1379 13.1352 20.0635 13.1352 21.2063L13.1425 31.0034V29.9696C13.1407 28.8268 12.2359 27.8994 11.1172 27.8984C10.0004 27.8984 9.09379 28.8233 9.09561 29.9661V30.3803C9.09379 29.2375 8.18717 28.3101 7.07034 28.3101C5.95169 28.3092 5.04689 29.2339 5.04872 30.3767V30.7908C5.04689 29.6472 4.14028 28.7215 3.02163 28.7206C1.9048 28.7197 1 29.6453 1 30.7872L1.0055 36.948C1.00915 42.3921 5.33021 46.8079 10.6548 46.8115C12.5941 46.8124 14.3982 46.2303 15.9081 45.2255C16.7873 44.6406 17.5678 43.9129 18.2167 43.0745Z"
        fill="white"
      />
      <path
        d="M18.2167 43.0745C21.4044 38.853 23.4023 31.9428 23.4023 31.9428C23.8739 30.9071 23.4352 29.6762 22.4207 29.1929C21.4081 28.7096 20.2054 29.1567 19.732 30.1913L17.193 35.7636H17.1949L17.1839 21.209C17.1821 20.0662 16.2755 19.1397 15.1586 19.1388C14.04 19.1379 13.1352 20.0635 13.1352 21.2063L13.1425 31.0034V29.9696C13.1407 28.8268 12.2359 27.8994 11.1172 27.8984C10.0004 27.8984 9.09379 28.8233 9.09561 29.9661V30.3803C9.09379 29.2375 8.18717 28.3101 7.07035 28.3101C5.95169 28.3092 5.04689 29.2339 5.04872 30.3767V30.7908C5.04689 29.6472 4.14028 28.7215 3.02163 28.7206C1.9048 28.7197 1 29.6453 1 30.7872L1.0055 36.948C1.00915 42.3921 5.33021 46.8079 10.6548 46.8115C12.5941 46.8124 14.3982 46.2303 15.9081 45.2255C16.7873 44.6406 17.5678 43.9129 18.2167 43.0745Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09473 30.3373L9.09646 31.3711L9.09473 30.3373Z"
        fill="#B0B7E4"
      />
      <path
        d="M9.09473 30.3373L9.09646 31.3711"
        stroke="#822949"
        strokeWidth="1.2813"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.0459 30.7487L5.0476 31.3691L5.0459 30.7487Z" fill="#B0B7E4" />
      <path
        d="M5.0459 30.7487L5.0476 31.3691"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25977 14.2285H10.4797V9.40029H4.25977V14.2285Z"
        fill="#F8D1D7"
      />
      <path
        d="M4.25977 14.2285H10.4797V9.40029H4.25977V14.2285Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25977 22.542H10.4797V17.7138H4.25977V22.542Z"
        fill="#F8D1D7"
      />
      <path
        d="M4.25977 22.542H10.4797V17.7138H4.25977V22.542Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2976 1.49699C37.1125 1.49699 35.2844 3.00862 34.7888 5.04254C34.2933 3.00862 32.4652 1.49699 30.2781 1.49699C27.7126 1.49699 25.6328 3.57714 25.6328 6.14348C25.6328 13.5224 34.7888 18.4424 34.7888 18.4424C34.7888 18.4424 43.9449 13.5224 43.9449 6.14348C43.9449 3.57714 41.8651 1.49699 39.2976 1.49699Z"
        fill="#F8D1D7"
      />
      <path
        d="M39.2976 1.49699C37.1125 1.49699 35.2844 3.00862 34.7888 5.04254C34.2933 3.00862 32.4652 1.49699 30.2781 1.49699C27.7126 1.49699 25.6328 3.57714 25.6328 6.14348C25.6328 13.5224 34.7888 18.4424 34.7888 18.4424C34.7888 18.4424 43.9449 13.5224 43.9449 6.14348C43.9449 3.57714 41.8651 1.49699 39.2976 1.49699Z"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9152 10.2125H36.6137L35.1895 7.28739L33.9972 12.6523L32.7991 10.0125H30.7275"
        stroke="#822949"
        strokeWidth="1.28129"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
