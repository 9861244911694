import styled from "styled-components";
import { StyledTabsHead, StyledTabsButton } from "../styles";
import { futureTheme } from "storybook/themes/future";
import { mobile } from "storybook/utils/breakpoints";

const { colors } = futureTheme;

const fontFamily = '"Raleway", sans-serif';

export const StyledTabsHeadFuture = styled(StyledTabsHead)<{
  $mobileStackTabs: boolean;
}>`
  position: relative;
  border-radius: 0;
  flex-direction: column;
  background-color: ${colors.white};

  ${({ $mobileStackTabs }) =>
    !$mobileStackTabs
      ? "flex-direction: row;"
      : `
      @media (min-width: 600px) {
        flex-direction: row;
      }
  `};
`;

export const StyledTabTextFuture = styled.div`
  font-size: 14px;
  font-family: ${fontFamily};
`;

export const StyledTabsButtonFuture = styled(StyledTabsButton)<{
  $disabled?: boolean;
  $mobileStackTabs: boolean;
}>`
  color: ${colors.black};
  box-sizing: border-box;
  height: 61px;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    color: ${colors.disabledGrey};
  `}

  @media (min-width: ${mobile}px) {
    height: 48px;
    border-radius: 0;
    text-transform: uppercase;
    color: ${colors.inactiveTabTabletText};
  }
`;

export const StyledTabsButtonActiveFuture = styled(StyledTabsButtonFuture)`
  cursor: default;
  position: relative;
  color: ${colors.activeTabMobileText};
  background-color: ${colors.activeTabMobileBg};

  @media (min-width: ${mobile}px) {
    color: ${colors.activeTabTabletText};
    background-color: ${colors.activeTabTabletBg};

    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      content: "";
      position: absolute;
      background-color: ${colors.activeTabTabletUnderline};
    }
  }
`;
