import React from "react";
import { MainWrapper, TitleWrapper } from "./styles";
import { Typography } from "storybook/components/typography";

export function Consent() {
  return (
    <MainWrapper>
      <TitleWrapper>
        <Typography theme="default" variant="h6" color="ciqBrandDark">
          <b>
            Share Your Personalized Risk Assessment Results With Your Provider
          </b>
        </Typography>
      </TitleWrapper>
      <Typography theme="default" variant="body1" color="ciqBrandDark">
        CancerIQ may display patient education content to you, which is provided
        by third party partners of CancerIQ.
        <br />
        CancerIQ can share de-identified, anonymous data about you with these
        third-party partners to better customize the educational content for
        you. CancerIQ will never share your identified health information with
        these third-party partners. Please read our{" "}
        <a href="https://www.canceriq.com/privacy-policy" target="_blank">
          Privacy Policy.
        </a>
      </Typography>
    </MainWrapper>
  );
}
