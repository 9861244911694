import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "storybook/components/tabs";
import { Header } from "components/header";
import { ScrollbarProvider } from "context/scrollbar";
import { TabIcon } from "./tab-icons/tab-icon";
import { HomeIcon } from "./tab-icons/home-icon";
import { RiskResultsIcon } from "./tab-icons/risk-results-icon";
import { EducationIcon } from "./tab-icons/education-icon";
import {
  GlobalStyles,
  TabsContainer,
  MainContainer,
  HeaderContainer,
  TabButtonContents,
  FullWidthContainer,
} from "./styles";

type Props = {
  children: ReactElement;
};

const homeTab = "home";
const resultsTab = "results";
const educationTab = "education";

const tabRoutes = {
  [homeTab]: "/",
  [resultsTab]: "/reports",
  [educationTab]: "/education",
};

export function PatientLayout({ children }: Props) {
  const location = useLocation();

  const showTabs = Object.values(tabRoutes).includes(location.pathname);

  return (
    <ScrollbarProvider>
      <>
        {showTabs && <GlobalStyles />}
        <HeaderContainer>
          <Header />
          {showTabs && (
            <TabsContainer data-id="TabsContainer">
              <Tabs
                theme="future"
                initialActiveId={homeTab}
                mobileStackTabs={false}
              >
                <Tabs.Head>
                  <Tabs.Button id={homeTab} route="/">
                    <TabButtonContents>
                      <TabIcon tabId={homeTab} component={HomeIcon} />
                      Home
                    </TabButtonContents>
                  </Tabs.Button>
                  <Tabs.Button id={resultsTab} route="/reports">
                    <TabButtonContents>
                      <TabIcon tabId={resultsTab} component={RiskResultsIcon} />
                      Risk Results
                    </TabButtonContents>
                  </Tabs.Button>
                  <Tabs.Button id={educationTab} route="/education">
                    <TabButtonContents>
                      <TabIcon tabId={educationTab} component={EducationIcon} />
                      Education
                    </TabButtonContents>
                  </Tabs.Button>
                </Tabs.Head>
              </Tabs>
            </TabsContainer>
          )}
        </HeaderContainer>
        <FullWidthContainer $showTabs={showTabs}>
          <MainContainer>{children}</MainContainer>
        </FullWidthContainer>
      </>
    </ScrollbarProvider>
  );
}
