import { useCallback } from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import { Button } from "storybook/components/button";
import { useKioskAuth } from "context/kiosk-auth";
import { useAdminContent } from "context/admin-content";
import { Loading } from "components/loading";
import { useRiskResultsKiosk } from "hooks/use-kiosk-risk-results";
import { Video } from "components/video";
import { getPatientIdentifier } from "helpers";
import { InterestModal } from "components/interest-modal";
import {
  ModalType,
  usePatientInterest,
  PatientInterestProvider,
} from "context/patient-interest";
import {
  Heading,
  BioImage,
  PageContainer,
  VideoContainer,
  ImageContainer,
  SchedulerButton,
  BioHtmlContainer,
  BioContainerColumn,
  PatientIdentifierContainer,
} from "./styles";
import {
  Column,
  MainContent,
  CopyContainer,
  ContentContainer,
  ColumnsContainer,
} from "pages/common/styles";
import { ResultsHeader } from "components/results-header";

function KioskResults() {
  const adminData = useAdminContent();
  const results = useRiskResultsKiosk();
  const { isLoading: isLoadingAuth } = useKioskAuth();
  const { modalType, interestLevel, setModalType } = usePatientInterest();

  const handleContinueClick = useCallback(() => {
    if (results?.lockScheduler) {
      setModalType(ModalType.LOCK_SCHEDULER);
    } else {
      setModalType(ModalType.INITIAL);
    }
  }, [results?.lockScheduler, setModalType]);

  // TODO: Do we really need to check all of these states?
  if (adminData?.isLoading || isLoadingAuth) {
    return <Loading />;
  }

  return (
    <PageContainer data-id="PageContainer">
      <ContentContainer>
        <ResultsHeader />
        <Heading>Patient Results Page</Heading>
        <MainContent>
          <ImageContainer>
            <img src={results?.headerImageUrl} alt="header image" />
          </ImageContainer>
          <ColumnsContainer>
            {results?.videoEnabled && (
              <Column>
                <VideoContainer>
                  <Video src={results?.videoUrl} label="results video" />
                </VideoContainer>
              </Column>
            )}
            {!results?.videoEnabled && results?.bioEnabled && (
              <BioContainerColumn>
                {results?.bioImageUrl.trim() && (
                  <BioImage src={results?.bioImageUrl} alt="bio image" />
                )}
                <BioHtmlContainer>
                  {HtmlParser(results?.bioHtml)}
                </BioHtmlContainer>
              </BioContainerColumn>
            )}
            <Column $single={!results?.videoEnabled}>
              <CopyContainer role="article">
                {HtmlParser(results?.bodyHtml)}
              </CopyContainer>
              {results?.showScheduler && interestLevel === null && (
                <SchedulerButton>
                  <Button
                    theme="default"
                    color="default"
                    variant="contained"
                    onClick={handleContinueClick}
                  >
                    Continue
                  </Button>
                </SchedulerButton>
              )}
            </Column>
          </ColumnsContainer>
        </MainContent>
        {results?.patientIdentifierEnabled && (
          <PatientIdentifierContainer>
            <strong>{getPatientIdentifier()}</strong>
          </PatientIdentifierContainer>
        )}
      </ContentContainer>
      {modalType && <InterestModal riskResults={results} />}
    </PageContainer>
  );
}

export function KioskResultsPage() {
  return (
    <PatientInterestProvider>
      <KioskResults />
    </PatientInterestProvider>
  );
}
