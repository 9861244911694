import { useState, useCallback, useEffect } from "react";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import { AccountIcon } from "./account-icon";
import { usePatientAuth } from "context/patient-auth";
import { useScrollbar } from "context/scrollbar";
import {
  Container,
  NavContent,
  AccountText,
  NavMenuItem,
  HomeMenuItem,
  NavSectionLeft,
  MenuButtonIcon,
  NavMenuItemLink,
  MenuContainer,
  ResultsLink,
  ResultsLinkText,
} from "./styles";

type Routes = {
  [key: string]: {
    title: string;
    backTo: string;
  };
};

const routesMap: Routes = {
  "/settings": {
    title: "Account Settings",
    backTo: "/",
  },
  "/settings/personal-information": {
    title: "Personal Information",
    backTo: "/settings",
  },
  "/settings/contact-information": {
    title: "Contact Information",
    backTo: "/settings",
  },
  "/settings/consent": {
    title: "Data Consent",
    backTo: "/settings",
  },
  "/reports/:reportId": {
    title: "Results",
    backTo: "/reports",
  },
};

export function Header() {
  const { logout } = usePatientAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const { scrollbarVisible, scrollbarWidth } = useScrollbar();

  const location = useLocation();
  const isReportPage = useRouteMatch("/reports/:reportId");
  const isSettingsPage = useRouteMatch("/settings");

  const isBackNav = isReportPage || isSettingsPage;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    handleClose();
    logout();
  }, [handleClose, logout]);

  useEffect(() => {
    if (isBackNav) {
      setAnchorEl(null);
    }
  }, [isBackNav]);

  if (isBackNav) {
    return (
      <Container
        $scrollbarWidth={scrollbarWidth}
        $scrollbarVisible={scrollbarVisible}
        $isBackNav={Boolean(isBackNav)}
      >
        <NavContent>
          <ResultsLink
            to={
              isReportPage ? "/reports" : routesMap[location.pathname]?.backTo
            }
          >
            <NavSectionLeft>
              <ChevronLeftSharpIcon />
            </NavSectionLeft>
            <ResultsLinkText variant="h6" color="ciqBrandDark">
              <b>
                {isReportPage ? "Results" : routesMap[location.pathname]?.title}
              </b>
            </ResultsLinkText>
          </ResultsLink>
        </NavContent>
      </Container>
    );
  }

  return (
    <Container
      $scrollbarWidth={scrollbarWidth}
      $scrollbarVisible={scrollbarVisible}
    >
      <NavContent>
        <NavSectionLeft>
          <Link to="/">
            <img alt="CancerIQ patient logo" src="/ciq-logo.png" width="135" />
          </Link>
        </NavSectionLeft>
        <MenuContainer>
          <MenuButtonIcon
            onClick={handleClick}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <AccountIcon />
            <AccountText>Account</AccountText>
          </MenuButtonIcon>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <HomeMenuItem className="home">
              <NavMenuItemLink to="/self-assessment">Home</NavMenuItemLink>
            </HomeMenuItem>
            <MenuItem>
              <NavMenuItemLink to="/settings">Edit Account</NavMenuItemLink>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <NavMenuItem>
                <ExitToAppIcon /> Log out
              </NavMenuItem>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </NavContent>
    </Container>
  );
}
