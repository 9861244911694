import styled from "styled-components";
import { tablet } from "styles/breakpoints";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 250px;
    height: 92.8px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      width: 387px;
      height: 151.39px;
    }
  }
`;

export function SecurityGraphic(): JSX.Element {
  return (
    <Wrapper>
      <svg
        width="250"
        height="94"
        viewBox="0 0 250 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M181.911 46.5932C181.911 31.123 194.452 18.582 209.923 18.582C225.393 18.582 237.934 31.123 237.934 46.5932C237.934 62.0629 225.393 74.6042 209.923 74.6042C194.452 74.6042 181.911 62.0629 181.911 46.5932Z"
          fill="#B8EEFF"
        />
        <path
          d="M197.28 52.6331C198.318 54.5808 199.646 56.3821 201.234 57.9708L208.8 65.5355C209.422 66.1585 210.431 66.1585 211.054 65.5355L218.619 57.9708C220.216 56.374 221.549 54.5617 222.59 52.6029C224.214 48.7019 225.285 44.4701 225.231 39.5611V35.416L209.927 27.126L194.622 35.416V39.5611C194.43 44.31 195.529 48.5904 197.28 52.6331Z"
          fill="#93F7F9"
        />
        <path
          d="M197.28 52.6331C198.318 54.5808 199.646 56.3821 201.234 57.9708L208.8 65.5355C209.422 66.1585 210.431 66.1585 211.054 65.5355L218.619 57.9708C220.216 56.374 221.549 54.5617 222.59 52.6029C224.214 48.7019 225.285 44.4701 225.231 39.5611V35.416L209.927 27.126L194.622 35.416V39.5611C194.43 44.31 195.529 48.5904 197.28 52.6331Z"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M220.449 39.5606V38.6042L209.927 32.8838L199.405 38.2854V39.5606C199.651 45.0733 200.174 50.2032 202.673 52.6027C203.251 53.419 203.9 54.191 204.617 54.9077L209.476 59.7667C209.725 60.015 210.129 60.015 210.378 59.7667L215.237 54.9077C215.954 54.1902 216.603 53.4224 217.179 52.6129C219.676 48.769 220.948 44.4827 220.449 39.5606Z"
          fill="white"
        />
        <path
          d="M220.449 39.5606V38.6042L209.927 32.8838L199.405 38.2854V39.5606C199.651 45.0733 200.174 50.2032 202.673 52.6027C203.251 53.419 203.9 54.191 204.617 54.9077L209.476 59.7667C209.725 60.015 210.129 60.015 210.378 59.7667L215.237 54.9077C215.954 54.1902 216.603 53.4224 217.179 52.6129C219.676 48.769 220.948 44.4827 220.449 39.5606Z"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.917 44.0645C210.94 44.0645 211.777 44.9016 211.777 45.9249V51.2715C211.777 52.2947 210.94 53.1319 209.917 53.1319C208.894 53.1319 208.057 52.2947 208.057 51.2715V45.9249C208.057 44.9016 208.894 44.0645 209.917 44.0645Z"
          fill="white"
        />
        <path
          d="M209.917 44.0645C210.94 44.0645 211.777 44.9016 211.777 45.9249V51.2715C211.777 52.2947 210.94 53.1319 209.917 53.1319C208.894 53.1319 208.057 52.2947 208.057 51.2715V45.9249C208.057 44.9016 208.894 44.0645 209.917 44.0645Z"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.917 37.8853C210.94 37.8853 211.777 38.7227 211.777 39.7457C211.777 40.7694 210.94 41.6062 209.917 41.6062C208.894 41.6062 208.057 40.7694 208.057 39.7457C208.057 38.7227 208.894 37.8853 209.917 37.8853Z"
          fill="white"
        />
        <path
          d="M209.917 37.8853C210.94 37.8853 211.777 38.7227 211.777 39.7457C211.777 40.7694 210.94 41.6062 209.917 41.6062C208.894 41.6062 208.057 40.7694 208.057 39.7457C208.057 38.7227 208.894 37.8853 209.917 37.8853Z"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M166.427 65.7324C159.184 81.4193 143.312 92.3046 124.897 92.3046C106.481 92.3046 90.6068 81.4197 83.3606 65.7333"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.776 28.0436C182.39 15.7348 195.39 7.36719 210.339 7.36719C225.29 7.36719 238.289 15.7348 244.903 28.0436"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.857788 46.5817C0.857788 68.2394 18.4152 85.7969 40.0733 85.7969C61.7318 85.7969 79.2885 68.2394 79.2885 46.5817C79.2885 46.5817 79.2863 45.1829 79.3191 44.4922C80.465 20.2118 100.437 0.85791 124.991 0.85791C149.37 0.85791 169.297 19.9352 170.646 43.9761C170.664 44.3193 170.696 46.5817 170.696 46.5817C170.696 68.2394 188.252 85.7969 209.911 85.7969C231.568 85.7969 249.126 68.2394 249.126 46.5817"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.50977 28.0432C12.1232 15.735 25.1226 7.36719 40.0735 7.36719C55.0243 7.36719 68.0238 15.735 74.6372 28.0432"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.7563 46.5819C90.7563 27.6741 106.084 12.3462 124.992 12.3462C143.9 12.3462 159.228 27.6741 159.228 46.5819C159.228 65.4897 143.9 80.8176 124.992 80.8176C106.084 80.8176 90.7563 65.4897 90.7563 46.5819Z"
          fill="#39286B"
          fillOpacity="0.3"
        />
        <path
          d="M12.0621 46.5823C12.0621 31.1121 24.6031 18.5708 40.0732 18.5708C55.5433 18.5708 68.0842 31.1121 68.0842 46.5823C68.0842 62.0516 55.5433 74.5929 40.0732 74.5929C24.6031 74.5929 12.0621 62.0516 12.0621 46.5823Z"
          fill="#B8EEFF"
        />
        <path
          d="M112.2 36.9312V33.6264C112.2 27.6338 117.075 22.7588 123.068 22.7588C129.059 22.7588 133.934 27.6338 133.934 33.6264V36.9312"
          fill="#FFD766"
        />
        <path
          d="M112.2 36.9312V33.6264C112.2 27.6338 117.075 22.7588 123.068 22.7588C129.059 22.7588 133.934 27.6338 133.934 33.6264V36.9312"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.289 36.932V33.8083C130.289 29.8932 126.981 26.5864 123.067 26.5864C119.152 26.5864 115.844 29.8932 115.844 33.8083V36.932"
          fill="white"
        />
        <path
          d="M130.289 36.932V33.8083C130.289 29.8932 126.981 26.5864 123.067 26.5864C119.152 26.5864 115.844 29.8932 115.844 33.8083V36.932"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.131 67.9322H108.548C107.464 67.9322 106.585 67.0537 106.585 65.97V38.893C106.585 37.8097 107.464 36.9312 108.548 36.9312H137.195C138.278 36.9312 139.156 37.8097 139.156 38.893V49.684"
          fill="#FFAC88"
        />
        <path
          d="M130.131 67.9322H108.548C107.464 67.9322 106.585 67.0537 106.585 65.97V38.893C106.585 37.8097 107.464 36.9312 108.548 36.9312H137.195C138.278 36.9312 139.156 37.8097 139.156 38.893V49.684"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127.776 45.565C127.776 48.1651 125.669 50.2737 123.068 50.2737C120.466 50.2737 118.359 48.1651 118.359 45.565C118.359 42.9641 120.466 40.856 123.068 40.856C125.669 40.856 127.776 42.9641 127.776 45.565Z"
          fill="white"
        />
        <path
          d="M127.776 45.565C127.776 48.1651 125.669 50.2737 123.068 50.2737C120.466 50.2737 118.359 48.1651 118.359 45.565C118.359 42.9641 120.466 40.856 123.068 40.856C125.669 40.856 127.776 42.9641 127.776 45.565Z"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127.384 54.9825C126.105 54.3276 124.637 53.8047 123.067 53.8047C117.894 53.8047 113.649 58.0506 113.649 63.223H126.992"
          fill="white"
        />
        <path
          d="M127.384 54.9825C126.105 54.3276 124.637 53.8047 123.067 53.8047C117.894 53.8047 113.649 58.0506 113.649 63.223H126.992"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.849 59.566C125.849 65.5518 130.701 70.4045 136.688 70.4045C142.673 70.4045 147.526 65.5518 147.526 59.566C147.526 53.5803 142.673 48.728 136.688 48.728C130.701 48.728 125.849 53.5803 125.849 59.566Z"
          fill="white"
        />
        <path
          d="M125.849 59.566C125.849 65.5518 130.701 70.4045 136.688 70.4045C142.673 70.4045 147.526 65.5518 147.526 59.566C147.526 53.5803 142.673 48.728 136.688 48.728C130.701 48.728 125.849 53.5803 125.849 59.566Z"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.84 51.9001C145.927 50.9871 144.449 50.9871 143.536 51.9001L136.248 59.1871L133.542 56.481C132.629 55.5681 131.15 55.5681 130.237 56.481C129.324 57.3932 129.324 58.8725 130.237 59.7854L134.397 63.9461C134.905 64.4533 135.586 64.6683 136.248 64.6117C136.911 64.6683 137.592 64.4533 138.1 63.9461L146.84 55.2049C147.753 54.2923 147.753 52.813 146.84 51.9001Z"
          fill="#FFD766"
        />
        <path
          d="M146.84 51.9001C145.927 50.9871 144.449 50.9871 143.536 51.9001L136.248 59.1871L133.542 56.481C132.629 55.5681 131.15 55.5681 130.237 56.481C129.324 57.3932 129.324 58.8725 130.237 59.7854L134.397 63.9461C134.905 64.4533 135.586 64.6683 136.248 64.6117C136.911 64.6683 137.592 64.4533 138.1 63.9461L146.84 55.2049C147.753 54.2923 147.753 52.813 146.84 51.9001Z"
          stroke="#133487"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.1631 62.7392L28.4452 30.1855L52.0099 30.3853L51.7267 63.0854"
          fill="white"
        />
        <path
          d="M28.1631 62.7392L28.4452 30.1855L52.0099 30.3853L51.7267 63.0854"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.7263 63.0855L51.7114 64.7777C51.6978 66.3924 50.3777 67.6895 48.763 67.6759L31.045 67.5226C29.4306 67.5089 28.1332 66.1889 28.147 64.5742L28.1629 62.7397"
          fill="#FFC9A1"
        />
        <path
          d="M51.7263 63.0855L51.7114 64.7777C51.6978 66.3924 50.3777 67.6895 48.763 67.6759L31.045 67.5226C29.4306 67.5089 28.1332 66.1889 28.147 64.5742L28.1629 62.7397"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.4607 28.3626C28.4747 26.7478 29.7951 25.45 31.4099 25.4645L49.1274 25.6179C50.7422 25.6314 52.0396 26.9521 52.0256 28.5661L52.0098 30.3843L28.4451 30.1851L28.4607 28.3626Z"
          fill="#FFC9A1"
        />
        <path
          d="M28.4607 28.3626C28.4747 26.7478 29.7951 25.45 31.4099 25.4645L49.1274 25.6179C50.7422 25.6314 52.0396 26.9521 52.0256 28.5661L52.0098 30.3843L28.4451 30.1851L28.4607 28.3626Z"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.6786 30.1855L51.742 30.3853"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.4916 63.0855L28.3954 62.8857"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.3222 54.6694C46.3222 55.3852 45.7359 55.9707 45.0209 55.9707H35.1526C34.4372 55.9707 33.8514 55.3852 33.8514 54.6694"
          fill="white"
        />
        <path
          d="M46.3222 54.6694C46.3222 55.3852 45.7359 55.9707 45.0209 55.9707H35.1526C34.4372 55.9707 33.8514 55.3852 33.8514 54.6694"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.8512 44.8023C33.8512 44.0861 34.4369 43.501 35.1524 43.501H45.0209C45.7363 43.501 46.3213 44.0861 46.3213 44.8023"
          fill="white"
        />
        <path
          d="M33.8512 44.8023C33.8512 44.0861 34.4369 43.501 35.1524 43.501H45.0209C45.7363 43.501 46.3213 44.0861 46.3213 44.8023"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.142 43.5006V40.2444C37.142 38.6254 38.4671 37.3003 40.0867 37.3003C41.7062 37.3003 43.0311 38.6254 43.0311 40.2444V43.5006"
          stroke="#21038C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.2041 46.6216L36.4646 48.8818"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4646 46.6689L34.2041 48.9296"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.0709 46.6216L41.3314 48.8818"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.3314 46.6689L39.0709 48.9296"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.9376 46.6216L46.1979 48.8818"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1983 46.6689L43.9371 48.9296"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.2043 50.7422L36.4648 53.0024"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4647 50.7891L34.2042 53.0502"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.0712 50.7422L41.3319 53.0024"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.3315 50.7891L39.0712 53.0502"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.938 50.7422L46.1983 53.0024"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1983 50.7891L43.9376 53.0502"
          stroke="#21038C"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
}
