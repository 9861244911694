import { useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { z } from "zod";

import { CircularProgress } from "@material-ui/core";
import {
  Container,
  FormContainer,
  StyledHeadings,
  TextContainer,
  ProgressContainer,
  ErrorMessage,
  PromptText,
} from "pages/styles/login";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { useForm } from "storybook/utils/form";
import { PrivacyLink } from "components/privacy-link";
import { usePatientAuth } from "context/patient-auth";
import { Row, ButtonsWrapper } from "./styles";
import { preserveQueryParams } from "helpers";

const forgotPasswordSchema = z
  .object({
    email: z.string().email({ message: "Please enter a valid email " }),
  })
  .required()
  .strict();

const initialValues = {
  email: "",
};

type ForgotPasswordFormState = z.infer<typeof forgotPasswordSchema>;

export default function ForgotPassword() {
  const {
    isAuthenticated,
    requestForgotPasswordReset,
    isSubmitting,
    setSubmitting,
    forgotPasswordError,
    setForgotPasswordError,
  } = usePatientAuth();

  const { Form, Input, getFieldState } = useForm<ForgotPasswordFormState>({
    schema: forgotPasswordSchema,
    values: initialValues,
    shouldFocusError: true,
  });

  const fieldError = Boolean(getFieldState("email").error);

  const handleSubmit = useCallback(
    (data: ForgotPasswordFormState) => {
      requestForgotPasswordReset(data.email);
    },
    [requestForgotPasswordReset]
  );

  useEffect(() => {
    if (fieldError) {
      setSubmitting(false);
      setForgotPasswordError(false);
    }
  }, [fieldError, setForgotPasswordError, setSubmitting]);

  if (isAuthenticated) {
    return <Redirect to={preserveQueryParams("/")} />;
  }

  const isDisabled = isSubmitting && !forgotPasswordError;

  return (
    <Container>
      <StyledHeadings data-id="StyledHeadings">
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Forgot password
        </Typography>
      </StyledHeadings>

      <TextContainer>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
          role="contentinfo"
        >
          Please enter your email address. We will send you an email to reset
          your password.
        </Typography>

        <PromptText>
          <Typography
            theme="default"
            variant="subtitle1"
            color="ebonyClay"
            component="p"
          >
            <strong>Your Email</strong>
          </Typography>
        </PromptText>
      </TextContainer>

      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Input
              hideDirty
              theme="default"
              type="email"
              label="Email address"
              name="email"
              labelPosition="top"
              alignText="left"
            />

            {!fieldError && forgotPasswordError && (
              <ErrorMessage>
                <Typography
                  theme="default"
                  variant="subtitle2"
                  color="ebonyClay"
                  component="p"
                >
                  Error sending email, please try again.
                </Typography>
              </ErrorMessage>
            )}
          </Row>

          <ButtonsWrapper>
            <Button
              type="submit"
              theme="default"
              variant="contained"
              color="secondary"
              disabled={isDisabled}
            >
              Reset Password
            </Button>

            {isSubmitting && (
              <ProgressContainer>
                <CircularProgress size={30} />
              </ProgressContainer>
            )}
          </ButtonsWrapper>

          <PrivacyLink />
        </Form>
      </FormContainer>
    </Container>
  );
}
