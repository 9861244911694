import React, { useCallback } from "react";
import { Modal } from "storybook/components/modal";
import { InitialModal } from "./initial-modal";
import { DeclineModal } from "./decline-modal";
import { ThankYouModal } from "./thank-you-modal";
import { ScheduleModal } from "./schedule-modal";
import { LockModal } from "../lock-modal";
import { ModalType, usePatientInterest } from "context/patient-interest";
import { GlobalModalStyles } from "./styles";

enum ModalTitle {
  INITIAL = "initial",
  DECLINE = "decline",
  THANK_YOU = "thank-you",
  EMAIL = "email",
  CALENDAR = "calendar",
  CONFIRMATION = "confirmation",
  LOCK_MODAL = "lock-modal",
}

const modalTitleMap = {
  [ModalTitle.INITIAL]: "Speak to a Specialist",
  [ModalTitle.DECLINE]: "Please select a reason",
  [ModalTitle.THANK_YOU]: "Thank you for completing the survey",
  [ModalTitle.EMAIL]: "Schedule Appointment",
  [ModalTitle.CALENDAR]: "When are you available?",
  [ModalTitle.CONFIRMATION]: "Thank you",
  [ModalType.LOCK_RESTART]: "",
  [ModalType.LOCK_SCHEDULER]: "",
  [ModalType.LOCK_PATIENT_HUB]: "",
};

const StepModalContents = {
  [ModalType.INITIAL]: InitialModal,
  [ModalType.DECLINE]: DeclineModal,
  [ModalType.THANK_YOU]: ThankYouModal,
  [ModalTitle.EMAIL]: ScheduleModal,
  [ModalTitle.CALENDAR]: ScheduleModal,
  [ModalTitle.CONFIRMATION]: ScheduleModal,
  [ModalType.LOCK_RESTART]: LockModal,
  [ModalType.LOCK_SCHEDULER]: LockModal,
  [ModalType.LOCK_PATIENT_HUB]: LockModal,
};

const getModalContents = (modalType?: ModalType) => {
  if (modalType) {
    return StepModalContents[modalType];
  }

  return null;
};

const getModalTitle = (modalType?: ModalType) => {
  if (modalType) {
    return modalTitleMap[modalType];
  }

  return null;
};

type Props = {
  riskResults: any;
};

export function InterestModal({ riskResults }: Props) {
  const { modalType, setModalType } = usePatientInterest();

  const ModalContents = getModalContents(modalType);
  const title = getModalTitle(modalType);

  const handleClose = useCallback(() => {
    setModalType(undefined);
  }, [setModalType]);

  if (!modalType || !ModalContents) {
    return null;
  }

  const isLockModal =
    modalType === ModalType.LOCK_RESTART ||
    modalType === ModalType.LOCK_SCHEDULER ||
    modalType === ModalType.LOCK_PATIENT_HUB;

  return (
    <>
      <GlobalModalStyles />
      <Modal
        theme="default"
        onClose={handleClose}
        open={Boolean(modalType)}
        disableBackdropClick={!isLockModal}
        hideCloseButton={isLockModal}
      >
        <Modal.Head alignTitle="left">{title}</Modal.Head>
        <ModalContents onClose={handleClose} riskResults={riskResults} />
      </Modal>
    </>
  );
}
