import { Typography } from "storybook/components/typography";
import { Video } from "components/video";
import { AppointmentIcon } from "./icons/appointment";
import { DoctorIcon } from "./icons/doctor";
import { RxIcon } from "./icons/rx";
import { RibbonIcon } from "./icons/ribbon";
import { SpecialistIcon } from "./icons/specialist";
import { TestResultsIcon } from "./icons/test-results";
import { VirusIcon } from "./icons/virus";
import { SectionContent } from "../../styles";
import {
  ListItem,
  VideoSection,
  ListContainer,
  VideoContainer,
  LearnMoreList,
  IconWrapper,
} from "./styles";

const listItems = [
  {
    Icon: RibbonIcon,
    text: "Understanding your risk for cancer",
    class: "ribbon",
  },
  { Icon: VirusIcon, text: "Your unique risk profile", class: "virus" },
  {
    Icon: SpecialistIcon,
    text: "A specialist can give you tailored recommendations",
    class: "specialist",
  },
  {
    Icon: TestResultsIcon,
    text: "Anxious about what these tests could reveal",
    class: "test-results",
  },
  { Icon: DoctorIcon, text: "Knowledge is power", class: "doctor" },
  { Icon: RxIcon, text: "Cost shouldn't be a barrier", class: "rx" },
  {
    Icon: AppointmentIcon,
    text: "Schedule your appointment with our specialist",
    class: "appointment",
  },
];

type Props = {
  videoUrl: string;
};

export function LearnMoreSection({ videoUrl }: Props) {
  return (
    <SectionContent className="video">
      <Typography theme="default" variant="h5" component="h2" color="black">
        You're eligible for further testing to uncover cancer risk factors
      </Typography>
      <VideoSection>
        <VideoContainer>
          <Video src={videoUrl} />
        </VideoContainer>
        <ListContainer>
          <LearnMoreList aria-labelledby="learn more list">
            {listItems.map(({ Icon, text, class: className }, index) => (
              <ListItem key={index} className={className}>
                <IconWrapper>
                  <Icon />
                </IconWrapper>
                {text}
              </ListItem>
            ))}
          </LearnMoreList>
        </ListContainer>
      </VideoSection>
    </SectionContent>
  );
}
