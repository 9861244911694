import styled from "styled-components";

export const StyledTabsButton = styled.div`
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  color: inherit;
`;

export const StyledTabsHead = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;
