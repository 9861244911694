export function RxIcon() {
  return (
    <svg fill="none" viewBox="0 0 36 35" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.6576 5.45644C5.6576 4.99096 6.03493 4.6139 6.50042 4.6139H14.9562C16.7879 4.6139 18.2779 5.72279 18.2779 7.08509C18.2779 7.74751 17.9309 8.36943 17.2974 8.83739C16.6667 9.30453 15.8404 9.56013 14.9342 9.55628L6.49767 9.53122C6.03219 9.52956 5.6576 9.15249 5.6576 8.68866V5.45644ZM34.8177 33.457C35.2887 33.457 35.5311 32.8946 35.2089 32.5525L34.1815 31.469L34.198 31.4596L25.0316 21.7979L33.3304 13.0493C33.6554 12.7067 33.4103 12.1412 32.9393 12.1451L29.4027 12.1701C28.7968 12.1748 28.2211 12.4244 27.8052 12.8631L22.1753 18.7863L16.9283 13.2578C16.7823 13.1022 16.8677 12.851 17.0771 12.8089C18.3881 12.542 19.5918 12.0266 20.5806 11.2934C22.0982 10.1708 22.9328 8.67709 22.9328 7.08509C22.9328 3.81266 19.3549 1.14977 14.9562 1.14977H2.24222C1.55639 1.14977 1 1.70532 1 2.39087V22.2199C1 22.7639 1.44069 23.2043 1.98605 23.2043H4.6743C5.2169 23.2043 5.6576 22.7639 5.6576 22.2199V12.9929L10.373 13.0066C10.7669 13.0083 11.147 13.1716 11.4196 13.4583L19.3219 21.7877L9.09224 32.552C8.76722 32.8929 9.00962 33.457 9.48061 33.457H13.0117C13.6231 33.457 14.207 33.2058 14.6285 32.7632L22.1808 24.801L29.736 32.7632C30.1546 33.2058 30.7386 33.457 31.35 33.457H34.8177Z"
        stroke="#822949"
        strokeWidth="1.1234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
