import styled from "styled-components";
import { mobile, tablet } from "styles/breakpoints";

const bulletPointBase64 =
  "PHN2ZwogICAgICB3aWR0aD0iMjQiCiAgICAgIGhlaWdodD0iMjQiCiAgICAgIGZpbGw9Im5vbmUiCiAgICAgIHZpZXdCb3g9IjAgMCAyNCAyNCIKICAgICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgPgogICAgICA8cGF0aAogICAgICAgIGQ9Ik04Ljk5OTkxIDE2LjE2OThMNC44Mjk5MSAxMS45OTk4TDMuNDA5OTEgMTMuNDA5OEw4Ljk5OTkxIDE4Ljk5OThMMjAuOTk5OSA2Ljk5OTg0TDE5LjU4OTkgNS41ODk4NEw4Ljk5OTkxIDE2LjE2OThaIgogICAgICAgIGZpbGw9IiMxYWIzOTQiCiAgICAgIC8+CiAgICA8L3N2Zz4=";

export const GraphicContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 30px 0 20px 20px;

  @media (min-width: ${mobile}px) {
    margin: 30px 0 0 20px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 25px;

  && {
    button {
      text-transform: none;
    }
  }
`;

export const WhySchedule = styled.div`
  margin: 0 10px 10px 33px;
  display: none;

  && {
    h3 {
      color: #48495c;
      font-weight: 700;
    }
  }

  @media (min-width: ${mobile}px) {
    display: block;
  }
`;

export const ListContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    color: #161616;
    margin-bottom: 10px;
    list-style-type: none;
  }

  li {
    color: #161616;
    padding-left: 34px;
    margin-bottom: 16px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url("data:image/svg+xml;base64,${bulletPointBase64}");

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Headings = styled.div`
  && {
    h2 {
      font-weight: 700;
      font-size: 20px;
    }

    @media (min-width: ${tablet}px) {
      h2 {
        font-size: 28px;
      }
    }
  }
`;
