import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import {
  Container,
  StyledHeadings,
  ProgressContainer,
  InfoBanner,
} from "pages/styles/login";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { usePatientAuth } from "context/patient-auth";
import {
  ButtonsWrapper,
  SentConfirmationText,
  BannerContainer,
} from "./styles";

export default function ForgotPasswordConfirm() {
  const history = useHistory();
  const {
    isSubmitting,
    emailAddress,
    requestForgotPasswordReset,
    isEmailSent,
  } = usePatientAuth();
  const [hasResent, setHasResent] = useState(false);

  const handleResend = useCallback(() => {
    if (emailAddress) {
      requestForgotPasswordReset(emailAddress);
      setHasResent(true);
    } else {
      history.push("/login/forgot");
    }
  }, [requestForgotPasswordReset, emailAddress, history]);

  return (
    <Container>
      <StyledHeadings data-id="StyledHeadings">
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Check your email
        </Typography>
      </StyledHeadings>
      {hasResent && isEmailSent && (
        <BannerContainer>
          <InfoBanner role="alert">Email with link sent!</InfoBanner>
        </BannerContainer>
      )}
      <SentConfirmationText>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
          role="contentinfo"
        >
          We've just sent you an email to reset your password.
        </Typography>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
        >
          Please click on Resend Email if you haven't received an email.
        </Typography>
      </SentConfirmationText>
      <ButtonsWrapper>
        <Button
          type="button"
          theme="default"
          variant="contained"
          color="secondary"
          onClick={handleResend}
          disabled={isSubmitting}
        >
          Resend Email
        </Button>
        {isSubmitting && (
          <ProgressContainer>
            <CircularProgress size={30} />
          </ProgressContainer>
        )}
      </ButtonsWrapper>
    </Container>
  );
}
