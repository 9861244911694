import { useState, useEffect, useRef, useCallback } from "react";
import { VideoContainer, PromptContainer } from "./styles";
import { Typography } from "storybook/components/typography";

type Props = {
  src?: string;
  label?: string;
};

type Subtitles = {
  [key: string]: {
    label: string;
    default?: boolean;
    src: string;
    prompt?: string;
  };
};

export function Video({ src, label = "welcome video" }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [subtitleData, setSubtitleData] = useState<Subtitles | null>(null);

  const BASE_URL = src?.substring(0, src.lastIndexOf("/"));

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    (async () => {
      try {
        const response = await fetch(`${BASE_URL}/subtitles.json`);
        if (response.ok) {
          const data: Subtitles = await response.json();
          setSubtitleData(data);

          Object.entries(data).forEach(async ([language, subtitle]) => {
            const response = await fetch(`${BASE_URL}/${subtitle.src}`);
            const text = await response.text();

            const vttBlob = new Blob([text], { type: "text/vtt" });

            const track = document.createElement("track");
            track.default = subtitle.default || false;
            track.src = URL.createObjectURL(vttBlob);
            track.label = subtitle.label;
            track.kind = "subtitles";
            track.srclang = language;

            video.append(track);
          });
        }
      } catch (error) {
        console.error("Error fetching subtitle data", error);
      }
    })();
  }, [BASE_URL]);

  const restartVideoTimeout = useCallback(() => {
    const interval = setInterval(() => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.currentTime = 1;
      }
    }, 300000); // 5 minutes

    return () => clearInterval(interval);
  }, []);

  const handleTrackEvent = useCallback(
    (event) => {
      if (subtitleData && subtitleData[event.track.language].default) {
        event.track.mode = "showing";
      } else {
        event.track.mode = "hidden";
      }
    },
    [subtitleData]
  );

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      if (typeof video.textTracks.addEventListener === "function") {
        video.textTracks.addEventListener("addtrack", handleTrackEvent);
      }

      const clearRestartInterval = restartVideoTimeout();

      return () => {
        if (typeof video.textTracks.removeEventListener === "function") {
          video.textTracks.removeEventListener("addtrack", handleTrackEvent);
        }

        clearRestartInterval();
      };
    }
  }, [handleTrackEvent, restartVideoTimeout]);

  if (!src) return null;

  return (
    <VideoContainer ref={videoContainerRef}>
      <video
        src={src}
        ref={videoRef}
        preload="true"
        aria-label={label}
        controls={true}
      ></video>
      {subtitleData && (
        <PromptContainer>
          {Object.values(subtitleData).map((subtitle) => (
            <Typography
              variant="body1"
              theme="default"
              color="ciqBrandDark"
              key={subtitle.label}
              component="p"
            >
              {subtitle.prompt}
            </Typography>
          ))}
        </PromptContainer>
      )}
    </VideoContainer>
  );
}
