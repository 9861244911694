type MaxLengthProps = {
  value: string;
  pattern?: string;
  maxLength?: number;
};

export function formatMaxLengthNumber({
  value,
  pattern,
  maxLength,
}: MaxLengthProps) {
  if (!maxLength) return value;

  let newValue = value;

  if (pattern) {
    const regex = new RegExp(`^${pattern}$`);
    newValue = newValue
      .split("")
      .filter((char) => regex.test(char))
      .join("");
  } else {
    // Fallback to allowing only digits if no pattern is provided
    newValue = newValue.replace(/\D/g, "");
  }

  // Truncate to maxLength if specified
  if (newValue.length > maxLength) {
    newValue = newValue.slice(0, maxLength);
  }

  return newValue;
}

type MinMaxProps = {
  value: string;
  min?: number;
  max?: number;
};

export function formatMinMaxNumber({ value, min, max }: MinMaxProps) {
  const output = parseFloat(value);

  if (Number.isNaN(output)) return null;

  if (min && output < min) {
    return min;
  }

  if (max && output > max) {
    return max;
  }

  return output;
}
