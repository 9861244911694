export const downloadPdf = ({ reportPdf }: { reportPdf: string | null }) => {
  if (!reportPdf) {
    alert("No PDF available to download.");
    return;
  }

  // Convert base64 to a Blob
  const byteCharacters = atob(
    reportPdf.replace(/^data:application\/pdf;base64,/, "")
  );
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // Trigger a download of the PDF
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = "CancerIQ Risk Report.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};
