import { ContentContainer, PageContainer } from "../common/styles";
import { ComingSoonIcon } from "./coming-soon-icon.tsx";
import { ComingSoonIconContainer } from "./styles.ts";

export function Education() {
  return (
    <PageContainer>
      <ContentContainer>
        <ComingSoonIconContainer>
          <ComingSoonIcon />
        </ComingSoonIconContainer>
      </ContentContainer>
    </PageContainer>
  );
}
