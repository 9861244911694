import styled from "styled-components";
import { ButtonsContainer } from "pages/styles/login";

export const BannerContainer = styled.div`
  margin-top: 20px;
`;

export const Row = styled.div`
  height: 87px;
`;

export const ButtonsWrapper = styled(ButtonsContainer)`
  margin-top: 11px;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  margin-top: -10px;

  & > a {
    font-size: 14px;
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
  }
`;
