import styled from "styled-components";
import { Button } from "storybook/components/button";

export const BannerContainer = styled.div`
  margin: 10px 0 20px 0;
`;

export const ContinueButton = styled(Button).attrs({
  type: "submit",
  // @ts-ignore cannot resolve against default theme
  theme: "default",
  color: "secondary",
})<{ $isSubmitting: boolean }>`
  && {
    margin-right: ${({ $isSubmitting }) => ($isSubmitting ? "60px" : "0")};

    @media (min-width: 768px) {
      margin-right: 0;
    }
  }
`;

export const Row = styled.div`
  height: 98px;
`;
