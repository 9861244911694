import styled from "styled-components";
import { tablet, desktop } from "styles/breakpoints";

export const VideoContainer = styled.div`
  max-width: 780px;
  overflow: hidden;
  flex: 2;

  .video-container {
    border-radius: 8px;
  }
`;

export const LearnMoreList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  .ribbon svg {
    width: 21px;
  }

  .virus svg {
    width: 26px;
  }

  .specialist svg {
    width: 26px;
  }

  .test-results svg {
    width: 26px;
  }

  .doctor svg {
    width: 21px;
  }

  .rx svg {
    width: 21px;
  }

  .appointment svg {
    width: 23px;
  }
`;

export const ListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 1100px) {
    margin-top: 25px;
  }

  @media (min-width: 1200px) {
    margin-top: 40px;
  }
`;

export const ListItem = styled.li`
  flex: 1;
  display: flex;
  font-size: 9px;
  align-items: center;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${tablet}px) {
    font-size: 14px;
    margin-bottom: 14px;
  }

  @media (min-width: ${desktop}px) {
    font-size: 16px;
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 28px;
  }
`;

export const VideoSection = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  ${ListContainer} {
    display: none;
  }

  @media (min-width: ${tablet}px) {
    gap: 20px;

    ${ListContainer} {
      display: flex;
    }
  }
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
