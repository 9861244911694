import styled from "styled-components";
import { desktop, mobile, tablet } from "styles/breakpoints";
import { HEADER_HEIGHT_TABLET } from "styles/global-styles";

export const MobileVideo = styled.div`
  margin-bottom: 40px;
  max-width: 535px;

  @media (min-width: ${tablet}px) {
    display: none;
  }
`;

export const ColumnsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${tablet}px) {
    flex-direction: row;
    height: calc(100vh - ${HEADER_HEIGHT_TABLET}px);
  }
`;

export const Column = styled.div<{ $single?: boolean; $bgColor?: string }>`
  height: 100%;
  display: flex;
  margin-top: 0;
  align-self: center;
  box-sizing: border-box;
  flex-direction: column-reverse;
  justify-content: ${({ $single }) => ($single ? "flex-start" : "center")};

  @media (min-width: ${tablet}px) {
    flex: 1;
    flex-direction: column;
  }
`;

export const LeftColumn = styled(Column)`
  display: block;
  position: relative;
  margin: 0 10px 0 10px;

  @media (min-width: ${tablet}px) {
    margin: 0;
  }
`;

export const RightColumn = styled(Column)`
  display: none;
  background-color: #dedee3;

  @media (min-width: ${tablet}px) {
    display: flex;
  }
`;

export const Scrollable = styled.div`
  padding: 0;
  overflow-y: auto;

  @media (min-width: ${tablet}px) {
    padding: 0 20px;
    height: calc(100% - 65px);
  }

  // Fixes an issue in iOS Safari where the footer buttons
  // are half swalloed by the bottom of the screen.
  @supports (-webkit-touch-callout: none) {
    @media (min-width: ${tablet}px) {
      height: calc(100% - 100px);
    }
  }
`;

export const ReportContainer = styled.div`
  padding-bottom: 80px;

  .logo {
    display: none;
  }

  @media (min-width: ${mobile}px) {
    padding-bottom: 40px;
  }

  @media (min-width: ${tablet}px) {
    padding-bottom: 20px;
  }
`;

type Args = {
  $single: boolean;
  $scrollbarVisible: boolean;
  $scrollbarWidth: number;
};

const getPadding = ({ $single, $scrollbarVisible, $scrollbarWidth }: Args) => {
  if ($single) {
    if ($scrollbarVisible) {
      return 0;
    } else {
      return `0 ${$scrollbarWidth}px 0 0`;
    }
  } else {
    if ($scrollbarVisible) {
      return 0;
    } else {
      return `0 ${$scrollbarWidth}px 0 0`;
    }
  }
};

export const FooterBar = styled.div<{
  $single: boolean;
  $scrollbarWidth: number;
  $scrollbarVisible: boolean;
}>`
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: ${getPadding};
  padding-top: 10px;
  padding-bottom: 10px;

  button {
    width: 100%;
    text-transform: capitalize;
  }

  @media (min-width: ${mobile}px) {
    height: 65px;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: ${tablet}px) {
    ${({ $single }) =>
      $single &&
      `
      padding-right: 20px;
    `};

    button {
      flex: 1;
    }
  }

  @media (min-width: ${desktop}px) {
    position: absolute;
  }

  // Fixes an issue in iOS Safari where the footer buttons
  // are half swalloed by the bottom of the screen.
  @media (min-width: ${desktop}px) {
    @supports (-webkit-touch-callout: none) {
      bottom: 35px;
    }
  }
`;

export const FooterContent = styled.div<{
  $single: boolean;
  $scrollbarWidth: number;
  $scrollbarVisible: boolean;
}>`
  flex: 1;
  gap: 10px;
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: center;
  flex-direction: column;
  flex: ${({ $single }) => ($single ? "none" : "1")};

  @media (min-width: ${mobile}px) {
    flex-direction: row;
  }

  @media (min-width: ${tablet}px) {
    flex-direction: row;
    max-width: 1200px;
    width: ${({ $single }) => ($single ? "535px" : "100%")};
  }
`;

export const CopyContainer = styled.div`
  color: #333;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  max-width: 535px;
  border: 1px solid #b6b6c1;
  margin: 20px auto 20px auto;

  @media (min-width: ${tablet}px) {
    margin: 30px auto 30px auto;
  }
`;

export const VideoContainer = styled.div`
  margin: 0 40px;
`;

export const ErrorContainer = styled.div`
  min-height: 250px;
`;

export const Content = styled.section`
  width: 100%;
  padding: 0;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
`;
