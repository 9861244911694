import styled from "styled-components";
import { mobile } from "styles/breakpoints";

export const DownloadButtonWrapper = styled.div`
  max-width: 535px;
  margin: 2rem auto;
`;

export const DateWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;

  @media (min-width: ${mobile}px) {
    padding-left: 25px;
  }
`;
