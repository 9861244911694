export function NurseIcon() {
  return (
    <svg
      width="69"
      height="69"
      fill="none"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4502 54.625C29.6258 54.625 32.2002 52.0506 32.2002 48.875C32.2002 45.6994 29.6258 43.125 26.4502 43.125C23.2746 43.125 20.7002 45.6994 20.7002 48.875C20.7002 52.0506 23.2746 54.625 26.4502 54.625Z"
        fill="url(#paint0_radial_4149_15664)"
      />
      <path
        d="M53.1877 32.775C60.0153 32.775 65.5502 27.2402 65.5502 20.4125C65.5502 13.5849 60.0153 8.05005 53.1877 8.05005C46.3601 8.05005 40.8252 13.5849 40.8252 20.4125C40.8252 27.2402 46.3601 32.775 53.1877 32.775Z"
        fill="#E3E3E3"
      />
      <path
        d="M44.8502 38.5254C44.8502 42.3338 41.372 45.4254 37.0877 45.4254C32.8034 45.4254 29.3252 42.3338 29.3252 38.5254"
        stroke="#161616"
        strokeWidth="1.725"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5254 47.7867V53.4729C15.5254 55.3796 17.0676 56.9252 18.97 56.9252H55.3184C57.1645 56.9252 58.6504 55.4247 58.6504 53.5857V47.798C58.6504 43.5898 55.3972 41.9088 53.6861 41.5929C48.1477 40.5888 43.296 38.0955 42.4405 37.317V34.2596C42.7107 34.237 42.9583 34.2144 43.1722 34.1919C46.2116 33.8534 48.5192 32.6913 50.0614 30.7396C51.7499 28.5847 52.3691 25.5724 51.885 21.8042C51.097 15.6555 47.7425 6.52827 41.7201 6.52827C41.0334 6.52827 40.358 6.65238 39.6826 6.90058C38.5231 6.51699 37.3637 6.3252 36.2155 6.3252C28.876 6.3252 23.2926 14.3693 22.3358 21.8155C21.8517 25.5837 22.4596 28.5847 24.1594 30.7508C25.6903 32.7139 28.0092 33.876 31.0486 34.2031C31.24 34.2257 31.4651 34.2483 31.7015 34.2596V37.3283C30.8572 38.0955 26.0505 40.6001 20.5009 41.6042C18.7674 41.8862 15.5254 43.5672 15.5254 47.7867Z"
        stroke="#161616"
        strokeWidth="1.725"
        strokeMiterlimit="10"
      />
      <path
        d="M46.1424 20.2036C46.1115 20.2036 46.0811 20.2044 46.0511 20.2062C44.9123 17.4783 43.4269 15.851 41.3897 13.968L40.5855 13.2246L39.9175 14.0878C37.9461 16.6353 35.1269 18.1015 32.7276 18.9883C31.534 19.4295 30.4666 19.7196 29.6762 19.932C29.6509 19.9388 29.6258 19.9455 29.6007 19.9522C29.2813 20.038 28.9848 20.1175 28.7565 20.1897C28.5478 20.0871 28.3064 20.0147 28.037 20.0147H28.0324C28.0076 20.0145 27.9312 20.0143 27.8494 20.0228C27.7676 20.0312 27.6368 20.0518 27.4911 20.1135C26.9065 20.3475 26.6754 20.8863 26.5807 21.213C26.4718 21.5893 26.4423 22.0212 26.4519 22.4421C26.4714 23.2959 26.6586 24.3319 26.9621 25.3133C27.2349 26.1978 27.5752 26.9554 27.9677 27.5148C28.2141 27.8659 28.5832 28.2827 29.0762 28.4922C30.508 32.663 33.0768 35.6496 37.0897 35.6496C41.0233 35.6496 43.5786 32.7593 45.0254 28.7214C45.2119 28.6519 45.3768 28.5548 45.5169 28.4509C45.7874 28.2502 46.0177 27.9875 46.2133 27.7069C46.6048 27.1455 46.9448 26.386 47.2174 25.5019L47.2187 25.4976C47.5154 24.5191 47.7022 23.4851 47.7232 22.6329C47.7335 22.2129 47.7055 21.7812 47.5978 21.405C47.5024 21.0714 47.2649 20.5137 46.6497 20.2877L46.5746 20.2601L46.4959 20.2447L46.4935 20.2441C46.4716 20.2392 46.3129 20.2036 46.1424 20.2036Z"
        stroke="#161616"
        strokeWidth="1.725"
        strokeMiterlimit="10"
      />
      <path
        d="M30.3119 52.3474C30.2553 52.3474 30.1991 52.3261 30.1553 52.2836C28.936 51.0906 27.4939 51.0706 27.4794 51.0706C26.018 51.0078 25.0872 50.5914 24.7039 49.8331C24.1181 48.6742 25.0541 47.1735 25.1735 46.9903C26.0577 45.0331 25.445 44.1295 25.4388 44.1206C25.368 44.0205 25.3901 43.8811 25.4895 43.809C25.5885 43.7373 25.7265 43.7573 25.7996 43.8556C25.8324 43.8998 26.5883 44.9589 25.5737 47.1911L25.5557 47.2232C25.5461 47.2373 24.6097 48.6563 25.1038 49.6316C25.4088 50.234 26.2116 50.5676 27.4894 50.6228H27.4905C27.5629 50.6228 29.121 50.6452 30.4678 51.9635C30.5562 52.0501 30.5579 52.1915 30.4713 52.2798C30.4275 52.3247 30.3695 52.3471 30.3112 52.3471L30.3119 52.3474Z"
        fill="#231F20"
      />
      <path
        d="M27.2897 54.207C27.2218 54.207 27.1552 54.1759 27.1114 54.1173C27.0786 54.0731 26.3227 53.014 27.3373 50.7818L27.3553 50.7497C27.3649 50.7356 28.3013 49.3166 27.8072 48.3413C27.5022 47.7389 26.6994 47.4053 25.4216 47.3501C25.3636 47.3501 23.798 47.3356 22.4428 46.0094C22.3545 45.9232 22.3528 45.7814 22.4394 45.6931C22.5256 45.6047 22.6678 45.6034 22.7557 45.6896C23.9812 46.8888 25.4164 46.9026 25.4309 46.9026C26.8926 46.9654 27.8234 47.3818 28.2067 48.1401C28.7925 49.299 27.8566 50.7997 27.7372 50.9829C26.853 52.9401 27.4657 53.8437 27.4719 53.8523C27.5426 53.9524 27.5205 54.0921 27.4212 54.1638C27.3812 54.1928 27.3349 54.207 27.2894 54.207H27.2897Z"
        fill="#231F20"
      />
      <path
        d="M22.8441 46.2399C22.7689 46.2399 22.6951 46.2019 22.653 46.1326C22.5885 46.027 22.6219 45.8894 22.7272 45.8248L25.5972 44.0722C25.7028 44.0081 25.8404 44.0412 25.905 44.1468C25.9695 44.2523 25.936 44.39 25.8308 44.4545L22.9607 46.2071C22.9242 46.2292 22.8838 46.2399 22.8441 46.2399Z"
        fill="#231F20"
      />
      <path
        d="M23.6419 46.7984C23.5657 46.7984 23.4912 46.7594 23.4494 46.689C23.3863 46.5828 23.4211 46.4454 23.5274 46.3823L25.7347 45.0706C25.841 45.0078 25.9783 45.0423 26.0414 45.1486C26.1046 45.2548 26.0697 45.3922 25.9634 45.4553L23.7561 46.767C23.7203 46.7884 23.6809 46.7984 23.6419 46.7984Z"
        fill="#231F20"
      />
      <path
        d="M27.1625 53.8799C27.0866 53.8799 27.0124 53.8412 26.9703 53.7709C26.9068 53.6646 26.9413 53.5273 27.0472 53.4638L29.9325 51.7364C30.0384 51.6729 30.1757 51.7074 30.2395 51.8133C30.303 51.9196 30.2685 52.0569 30.1626 52.1204L27.2773 53.8478C27.2415 53.8695 27.2018 53.8795 27.1625 53.8795V53.8799Z"
        fill="#231F20"
      />
      <path
        d="M27.0385 52.9132C26.9633 52.9132 26.8902 52.8753 26.8478 52.8066C26.7829 52.7014 26.8157 52.5634 26.9209 52.4985L29.1079 51.1534C29.2131 51.0889 29.3511 51.1213 29.4159 51.2269C29.4808 51.3321 29.448 51.4701 29.3428 51.5349L27.1558 52.8798C27.1193 52.9022 27.0789 52.9129 27.0385 52.9129V52.9132Z"
        fill="#231F20"
      />
      <path
        d="M25.0726 50.1087C24.9967 50.1087 24.9225 50.0697 24.8804 49.9997C24.817 49.8934 24.8515 49.7561 24.9574 49.6926L27.8426 47.9655C27.9485 47.9021 28.0862 47.9366 28.1497 48.0425C28.2131 48.1487 28.1786 48.286 28.0727 48.3495L25.1875 50.0766C25.1516 50.0983 25.1119 50.1083 25.0726 50.1083V50.1087Z"
        fill="#231F20"
      />
      <path
        d="M25.9663 50.815C25.8911 50.815 25.8179 50.777 25.7755 50.7083C25.7106 50.6031 25.7434 50.4651 25.8486 50.4003L28.0356 49.0551C28.1405 48.9906 28.2788 49.023 28.3437 49.1286C28.4085 49.2338 28.3758 49.3718 28.2705 49.4367L26.0836 50.7818C26.047 50.8043 26.0066 50.815 25.9663 50.815Z"
        fill="#231F20"
      />
      <path
        d="M24.729 49.1468C24.6541 49.1468 24.5676 49.1157 24.5386 49.0408C24.5251 49.0056 24.5058 48.7976 24.611 48.7328L26.9746 47.2689C27.0798 47.2037 27.2178 47.2365 27.2827 47.3414C27.3479 47.4466 27.3155 47.5846 27.2103 47.6495L24.8467 49.1133C24.8101 49.1361 24.769 49.1468 24.729 49.1468Z"
        fill="#231F20"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4149_15664"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.4502 48.875) rotate(45) scale(8.13173)"
        >
          <stop stopColor="#76BE81" />
          <stop offset="1" stopColor="#59B99F" />
        </radialGradient>
      </defs>
    </svg>
  );
}
