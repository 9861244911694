import styled from "styled-components";
import { ButtonsContainer } from "pages/styles/login";

export const ButtonsWrapper = styled(ButtonsContainer)`
  margin-top: 11px;

  button {
    text-transform: none;
  }
`;

export const SentConfirmationText = styled.div`
  margin-top: 20px;

  && {
    p {
      margin-bottom: 16px;
      line-height: 24px;

      strong {
        word-break: break-word;
      }
    }
  }
`;

export const BannerContainer = styled.div`
  margin-top: 30px;
`;
