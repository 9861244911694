import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  padding: 25px;

  > :last-child {
    margin-top: 38px;
  }
`;

export const LinkWrapper = styled.div`
  color: #00639b;
`;

export const SettingsLink = styled(Link)`
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cac4d0;
  text-decoration: none;
  color: inherit;
  font-weight: 500;
`;

export const MainWrapper = styled.div`
  padding: 25px;
`;

export const FormContainer = styled.div`
  > * {
    margin-bottom: 32px;
  }
`;

export const IsSubmittingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: 12px;
  }

  > div {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;
