import { useState, useEffect, useCallback } from "react";
import { z } from "zod";
import { Redirect, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "storybook/components/typography";
import { Notification } from "storybook/components/notification";
import { useForm } from "storybook/utils/form";
import { preserveQueryParams } from "helpers";
import { usePatientAuth } from "context/patient-auth";
import { PrivacyLink } from "components/privacy-link";
import {
  Container,
  BackContainer,
  FormContainer,
  StyledHeadings,
  ButtonsContainer,
  ProgressContainer,
} from "pages/styles/login";
import { Row, BannerContainer, ContinueButton, TextContainer } from "./styles";

const patientSchema = z.object({
  patient: z
    .object({
      email: z.string().email({ message: "Please enter a valid email " }),
    })
    .required()
    .strict(),
});

type Patient = z.infer<typeof patientSchema>;

export function ResendCodeEmail() {
  const history = useHistory();
  const {
    isSubmitting,
    setSubmitting,
    emailLinkError,
    isAuthenticated,
    sendEmailWithCode,
    setEmailLinkError,
    passwordlessError,
    setPasswordlessError,
  } = usePatientAuth();

  const [postObj] = useState<Patient>(() => {
    const email = sessionStorage.getItem("prepopulateEmail");

    return {
      patient: {
        email: email || "",
      },
    };
  });

  const { Form, Input, getFieldState } = useForm<Patient>({
    schema: patientSchema,
    values: postObj,
    shouldFocusError: true,
  });

  const handleSubmit = useCallback(
    (data: Patient) => {
      setSubmitting(true);
      setPasswordlessError(false);
      setEmailLinkError(undefined);

      // This method does not return a Promise, therefore we don't know when it finishes.
      // We rely on state updates in the auth context to control what happens next in this component.
      sendEmailWithCode(data.patient.email);
    },
    [sendEmailWithCode, setEmailLinkError, setPasswordlessError, setSubmitting]
  );

  const handleBackClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      // User is going back to the main passwordless page, so clear the error state.
      setEmailLinkError(undefined);

      history.push({
        pathname: "/login/passwordless",
        search: location.search,
      });
    },
    [history, setEmailLinkError]
  );

  const fieldError = Boolean(getFieldState("patient.email").error);

  useEffect(() => {
    if (fieldError) {
      setPasswordlessError(false);
    }
  }, [fieldError, setPasswordlessError]);

  if (isAuthenticated) {
    return <Redirect to={preserveQueryParams("/")} />;
  }

  const isDisabled = isSubmitting && !passwordlessError;

  return (
    <Container>
      <StyledHeadings>
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Enter Your Email
        </Typography>
      </StyledHeadings>
      {emailLinkError && (
        <BannerContainer>
          <Notification
            theme="default"
            variant="warning"
            fullWidth={true}
            title="Error sending the email, please try again"
          />
        </BannerContainer>
      )}
      <TextContainer>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
        >
          Please enter your email address. We will send you a{" "}
          <strong>code</strong> to access the questionnaire.
        </Typography>
      </TextContainer>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Input
              theme="default"
              type="email"
              label="Email address"
              name="patient.email"
              labelPosition="top"
              alignText="left"
              hideDirty
            />
          </Row>
          <ButtonsContainer>
            <ContinueButton
              variant="contained"
              disabled={isDisabled}
              $isSubmitting={isSubmitting}
            >
              Send Me a Code
            </ContinueButton>
            {isSubmitting && (
              <ProgressContainer>
                <CircularProgress size={30} />
              </ProgressContainer>
            )}
          </ButtonsContainer>
        </Form>
      </FormContainer>
      <BackContainer>
        <a href="#" onClick={handleBackClick}>
          <ArrowBackIcon />
          Back to Sign in
        </a>
      </BackContainer>
      <PrivacyLink />
    </Container>
  );
}
