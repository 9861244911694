import styled, { createGlobalStyle } from "styled-components";
import { mobile, tablet } from "styles/breakpoints";

export const GlobalModalStyles = createGlobalStyle`
  [role="presentation"] {
    .MuiDialog-paper {
      overflow: visible; // Needed for date picker overlay
      padding: 2rem;
      margin: 0;
    }

    .close-btn {
      margin-right: 0;
    }
  }

  .MuiPaper-root {
    overflow: visible;
    max-height: 100vh !important;
  }

  .MuiDialogTitle-root {
    h2 {
      font-size: 28px !important;
      padding-right: 50px;
      margin-top: -5px;
    }
  }

  .MuiDialogActions-root {
    button {
      text-transform: capitalize;
    }
  }
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column-reverse;

  @media (min-width: ${tablet}px) {
    flex-direction: row;
    justify-content: center;

    button {
      flex: 1;
      width: auto;
    }
  }
`;

export const Banner = styled.div`
  padding: 6px 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 20px;
`;

export const ErrorBanner = styled(Banner)`
  color: #9f4f4b;
  background-color: #efdfde;
  border: 1px solid #e6cdd1;
`;

export const InputsContainer = styled.div`
  margin-top: 20px;
  min-height: 140px;

  .input-group + div {
    margin-top: 10px;
  }
`;

export const ConfirmButtons = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media (min-width: ${mobile}px) {
    flex-direction: row;
    gap: 10px;
  }
`;

export const ModalBodyWrapper = styled.div`
  word-break: break-word;
  line-height: 26px;
`;
