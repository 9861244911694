import styled from "styled-components";
import { StyledTabsHead, StyledTabsButton } from "../styles";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

export const StyledTabsHeadDefault = styled(StyledTabsHead)<{
  $mobileStackTabs: boolean;
}>`
  padding: 2px;
  position: relative;
  border-radius: 0.5rem;
  flex-direction: column;
  background-color: ${colors.greyBright};

  ${({ $mobileStackTabs }) =>
    !$mobileStackTabs
      ? "flex-direction: row;"
      : `
      @media (min-width: 600px) {
        flex-direction: row;
      }
  `};
`;

export const StyledTabTextDefault = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  font-family: ${defaultTheme.typography.fontFamily};
`;

export const StyledTabsButtonDefault = styled(StyledTabsButton)<{
  $disabled?: boolean;
  $mobileStackTabs: boolean;
}>`
  color: ${colors.ciqBrand};
  box-sizing: border-box;
  padding: 8px 14px;
  border-radius: 0.5rem;
  line-height: 23px;
  font-size: 1.125rem;

  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    color: ${colors.grey};
  `}

  // IE11
  *::-ms-backdrop,
  & {
    padding: 20px;

    ${({ $mobileStackTabs }) =>
      $mobileStackTabs &&
      `@media (min-width: 600px) {
         padding: 8px 14px;
       }
      `}
  }
`;

export const StyledTabsButtonActiveDefault = styled(StyledTabsButtonDefault)`
  cursor: default;
  color: ${colors.hematite};
  background-color: ${colors.white};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
`;
