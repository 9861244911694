import styled from "styled-components";
import { Typography } from "storybook/components/typography";
import { StyledTabsHead, StyledTabsButton } from "../styles";
import { legacyTheme } from "storybook/themes/legacy";

const { colors } = legacyTheme;

export const StyledTabTextLegacy = styled(Typography).attrs({
  color: "greyStorm",
  theme: "legacy",
  variant: "body1",
  component: "div",
})``;

export const StyledTabsHeadLegacy = StyledTabsHead;

export const StyledTabsButtonLegacy = styled(StyledTabsButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  padding: 14px 20px 14px 25px;
  border-bottom: 2px solid transparent;

  &:focus,
  &:active {
    border-radius: 4px 4px 0 0;
    outline-offset: -4px;
  }

  &:hover {
    border-radius: 4px 4px 0 0;
    background-color: ${colors.greyBright};
    border-bottom: 2px solid ${colors.greyStorm};
  }
`;

export const StyledTabsButtonActiveLegacy = styled(StyledTabsButtonLegacy)`
  border-bottom: 2px solid ${colors.cosmic};
`;
