import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { tablet } from "styles/breakpoints";

const { colors } = defaultTheme;

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  min-width: 320px;
  max-width: 696px;
  flex-direction: column;
  padding: 27px 16px 16px 16px;

  button {
    width: 100%;
  }
`;

export const PromptContainer = styled.div`
  margin-top: 8px;

  @media (min-width: ${tablet}px) {
    margin-top: 0;
  }

  && {
    p {
      color: ${colors.hematite};
    }
  }
`;

export const HeadingContainer = styled.div`
  margin-top: -2px;

  && {
    h1 {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 16px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;

  && {
    button {
      text-transform: none;
    }
  }

  @media (min-width: ${tablet}px) {
    margin-top: 32px;
  }
`;

export const SummaryContainer = styled.div`
  margin-top: 17px;

  @media (min-width: ${tablet}px) {
    margin-top: 32px;
  }

  // Temporary overrides until new Typography designs are implemented
  && {
    h2 {
      color: #4a4d5e;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 13px;
    }
  }

  @media (min-width: ${tablet}px) {
    && {
      h2 {
        padding-bottom: 16px;
      }
    }
  }
`;

export const NotificationContainer = styled.div`
  margin-top: 24px;
`;
