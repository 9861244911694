import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { DateOfBirth } from "storybook/components/date-of-birth";
import { useForm } from "storybook/utils/form";
import { ErrorBanner, ProgressContainer } from "pages/styles/login";
import { usePatientAuth } from "context/patient-auth";
import {
  Row,
  BannerContainer,
  HeadingContainer,
  ButtonContainer,
  FormContainer,
  FieldsContainer,
} from "./styles";
import { formatPhone } from "helpers";
import { SPECIALIST_HOSTNAME } from "../../env-vars";
import { Account, accountSchema } from "./form-schema";

enum PatientMatchError {
  GENERAL_ERROR = "general",
  UNABLE_TO_CREATE = "unable_to_create",
}

// eslint-disable-next-line react-refresh/only-export-components
export const errorMessage: Record<PatientMatchError, string> = {
  [PatientMatchError.GENERAL_ERROR]:
    "An error occurred, please try again or contact support.",
  [PatientMatchError.UNABLE_TO_CREATE]:
    "It looks like we couldn't find your patient record. Verify your information or reach out to your health system for support.",
};

export default function MatchPatient() {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<PatientMatchError | null>(null);
  const { isAuthenticated } = usePatientAuth();
  const [formState] = useState<Account>({
    account: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      // @ts-ignore needs to be blank initially but a value must be selected
      birthSex: undefined,
      // @ts-ignore needs to be blank for placeholder text to show
      dateOfBirth: undefined,
    },
  });

  const { Form, Input, Select } = useForm<Account>({
    schema: accountSchema,
    values: formState,
    shouldFocusError: true,
  });

  if (!isAuthenticated) {
    history.push("/login/passwordless");
  }

  const handleSubmit = useCallback(
    async (data: Account) => {
      setError(null);
      setSubmitting(true);

      const { account } = data;

      const jwt = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/find_and_link`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              first_name: account.firstName,
              last_name: account.lastName,
              date_of_birth: account.dateOfBirth,
              phone_number: account.phoneNumber?.replace(/\D/g, ""),
              birth_sex: account.birthSex,
            }),
          }
        );

        setSubmitting(false);

        if (response.status === 403) {
          setError(PatientMatchError.UNABLE_TO_CREATE);

          return;
        }

        if (!response.ok) {
          setError(PatientMatchError.GENERAL_ERROR);

          return;
        }

        const json = await response.json();

        if (json.person_id && json.location_id) {
          localStorage.setItem("ciq_id", json.person_id);
          localStorage.setItem("location_id", json.location_id);

          history.push("/");
        } else {
          setError(PatientMatchError.GENERAL_ERROR);
        }
      } catch (error) {
        setSubmitting(false);
        setError(PatientMatchError.GENERAL_ERROR);
      }
    },
    [history]
  );

  return (
    <div>
      <HeadingContainer>
        <Typography
          theme="default"
          variant="h4"
          color="ebonyClay"
          component="h1"
        >
          We need some more information to locate your patient record
        </Typography>
      </HeadingContainer>
      {error && (
        <BannerContainer>
          <ErrorBanner role="alert">{errorMessage[error]}</ErrorBanner>
        </BannerContainer>
      )}
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <FieldsContainer>
            <Row>
              <Input
                type="text"
                theme="default"
                label="First Name*"
                name="account.firstName"
                placeholder="First Name"
                disabled={isSubmitting}
                autoComplete="off"
                labelPosition="top"
                alignText="left"
                hideDirty
              />
            </Row>
            <Row>
              <Input
                type="text"
                theme="default"
                label="Last Name*"
                name="account.lastName"
                placeholder="Last Name"
                disabled={isSubmitting}
                autoComplete="off"
                labelPosition="top"
                alignText="left"
                hideDirty
              />
            </Row>
            <Row>
              <DateOfBirth
                theme="default"
                label="Date of Birth*"
                disabled={isSubmitting}
                name="account.dateOfBirth"
                maxMonthListHeight="30vh"
                disablePortal={true}
              />
            </Row>
            <Row>
              <Select
                theme="default"
                label="Birth Sex*"
                alignText="left"
                name="account.birthSex"
                placeholder="Select Birth Sex"
                disabled={isSubmitting}
                labelPosition="top"
                hideDirty
                disablePortal={true}
              >
                <Select.Option value={1}>Male</Select.Option>
                <Select.Option value={0}>Female</Select.Option>
              </Select>
            </Row>
            <Row>
              <Input
                type="tel"
                theme="default"
                label="Phone Number*"
                name="account.phoneNumber"
                disabled={isSubmitting}
                autoComplete="off"
                labelPosition="top"
                alignText="left"
                hideDirty
                registerOptions={{
                  // onChange ensures the field shows the correct sanitized value
                  onChange: (event) =>
                    (event.target.value = formatPhone(event.target.value)),
                  // setValueAs is also required to ensure the sanitized value is what gets submitted
                  // otherwise you could submit something like a1234 even though
                  // the field is correctly showing 1234 due to the sequence of events in RHF
                  setValueAs: (value) => formatPhone(value),
                }}
              />
            </Row>
          </FieldsContainer>
          <ButtonContainer>
            <Button
              type="submit"
              theme="default"
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
            >
              Find Account
            </Button>
            {isSubmitting && (
              <ProgressContainer>
                <CircularProgress size={30} />
              </ProgressContainer>
            )}
          </ButtonContainer>
        </Form>
      </FormContainer>
    </div>
  );
}
