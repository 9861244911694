import { SPECIALIST_HOSTNAME } from "env-vars";

type Props = {
  isInitial: boolean;
  personId?: string;
  isRemote: boolean;
};

export async function createSurveySession({
  isInitial,
  personId,
  isRemote,
}: Props) {
  const patientId = personId || localStorage.getItem("ciq_id");
  const idToken = localStorage.getItem("id_token");

  return fetch(`${SPECIALIST_HOSTNAME}/api/v1/survey_sessions/create_session`, {
    method: "POST",
    body: JSON.stringify({
      person_id: patientId,
      is_remote: isRemote,
      is_initial_screening: isInitial,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
}
