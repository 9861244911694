import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";

// eslint-disable-next-line react-refresh/only-export-components
export enum ModalType {
  INITIAL = "initial",
  DECLINE = "decline",
  THANK_YOU = "thank-you",
  EMAIL = "email",
  CALENDAR = "calendar",
  CONFIRMATION = "confirmation",
  LOCK_RESTART = "lock-restart",
  LOCK_SCHEDULER = "lock-scheduler",
  LOCK_PATIENT_HUB = "lock-patient-hub",
}

// eslint-disable-next-line react-refresh/only-export-components
export enum InterestLevel {
  DECLINED = "DECLINED",
  INTERESTED = "INTERESTED",
  NEED_TIME = "NEED_TIME",
}

// eslint-disable-next-line react-refresh/only-export-components
export enum InterestReason {
  NONE = "NONE",
  OTHER = "OTHER",
  INSURANCE = "INSURANCE",
  TRANSPORTATION = "TRANSPORTATION",
  TIME_CONSTRAINT = "TIME_CONSTRAINT",
  DOES_NOT_WANT_TO_KNOW = "DOES_NOT_WANT_TO_KNOW",
  ALREADY_BEING_FOLLOWED = "ALREADY_BEING_FOLLOWED",
  PREVIOUS_FAMILY_TESTING = "PREVIOUS_FAMILY_TESTING",
  PREVIOUS_PERSONAL_TESTING = "PREVIOUS_PERSONAL_TESTING",
}

type SaveInterestProps = {
  level: InterestLevel;
  reason?: InterestReason;
};

// eslint-disable-next-line react-refresh/only-export-components
export enum ErrorType {
  AUTH = "AUTH",
  GENERAL = "GENERAL",
}

type PatientInterestContextType = {
  loading: boolean;
  errorType: ErrorType | null;
  modalType: ModalType | undefined;
  interestLevel: InterestLevel | null;
  setErrorType: (errorType: ErrorType | null) => void;
  setInterestLevel: (level: InterestLevel) => void;
  saveInterest: (props: SaveInterestProps) => Promise<void>;
  setModalType: (modalType?: ModalType) => void;
};

const PatientInterestContext = createContext<
  PatientInterestContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

export function PatientInterestProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState<ErrorType | null>(null);
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);
  const [interestLevel, setInterestLevel] = useState<InterestLevel | null>(
    null
  );
  const abortControllerRef = useRef<AbortController | null>(null);

  // After completing an initial survey, "ciq-questionnaire.sessionId" is set by
  // Questionnaire and this is the patient id.
  // After completing a rescreen, we use "ciq_id", which comes from Auth0 upon login.
  const patientIdFromQuestionnaire = localStorage.getItem(
    "ciq-questionnaire.sessionId"
  );
  const patientIdFromAuth0 = localStorage.getItem("ciq_id");
  // If both an auth0 id and sessionId are present, we should prefer the auth0 id
  const patientId = patientIdFromAuth0 || patientIdFromQuestionnaire;

  const idToken = localStorage.getItem("id_token");

  const saveInterest = useCallback(
    async ({ level, reason }: SaveInterestProps) => {
      setLoading(true);
      setErrorType(null);

      // Cancel any ongoing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for this request
      abortControllerRef.current = new AbortController();

      try {
        const payload = {
          level,
          ...(reason ? { reason } : {}),
        };

        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/genetics_interest`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              data: payload,
            }),
            signal: abortControllerRef.current.signal,
          }
        );

        if (response.ok) {
          const res = await response.json();
          setModalType(ModalType.THANK_YOU);
          setInterestLevel(res.data);
        } else {
          setErrorType(ErrorType.GENERAL);
        }
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          setErrorType(ErrorType.GENERAL);
          console.error("Error updating genetics interest: ", error);
        }
      } finally {
        setLoading(false);
        abortControllerRef.current = null;
      }
    },
    [idToken, patientId]
  );

  const setModal = useCallback((modal?: ModalType) => {
    setModalType(modal);

    if (!modal) {
      setErrorType(null);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchInterestLevel = async () => {
      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/genetics_interest`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            signal: abortController.signal,
          }
        );

        if (response.ok) {
          const res = await response.json();
          setInterestLevel(res.data);
        }
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          console.error("Error fetching genetics interest: ", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInterestLevel();

    return () => {
      abortController.abort();
    };
  }, [idToken, patientId]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const contextProps = useMemo(
    () => ({
      loading,
      errorType,
      modalType,
      setErrorType,
      saveInterest,
      interestLevel,
      setInterestLevel,
      setModalType: setModal,
    }),
    [loading, errorType, modalType, interestLevel, saveInterest, setModal]
  );

  return (
    <PatientInterestContext.Provider value={contextProps}>
      {children}
    </PatientInterestContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePatientInterest() {
  const context = useContext(PatientInterestContext);

  if (context === undefined) {
    throw new Error(
      "usePatientInterest must be used within a PatientInterestProvider"
    );
  }

  return context;
}
