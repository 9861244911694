import styled from "styled-components";
import { Button } from "storybook/components/button";
import { defaultTheme } from "storybook/themes/default";
import { mobile, tablet } from "styles/breakpoints";

const { colors } = defaultTheme;

export const SentConfirmationText = styled.div`
  margin-top: 20px;

  && {
    p {
      margin-bottom: 16px;
      line-height: 24px;

      strong {
        word-break: break-word;
      }
    }
  }
`;

export const CodeContainer = styled.div`
  margin: 20px 0;
  position: relative;

  && {
    label {
      margin-bottom: 30px;
    }

    input {
      letter-spacing: 10px;
      color: ${colors.ciqBrand} !important;
      // Using Dosis here as the numbers render uneven vertically with Raleway
      font-family: "Dosis", sans-serif !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Hide arrows for Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    // Do not use error colour on border as it is not required.
    // The submit button is disabled when 6 digits not entered.
    input + fieldset {
      border: 1px solid rgba(0, 0, 0, 0.26) !important;
    }

    input:focus + fieldset {
      border: 2px solid ${colors.ciqBrand} !important;
    }
  }
`;

export const PasswordlessLink = styled.div`
  margin: 22px 0 12px 0;

  a {
    font-weight: 700;
    color: ${colors.ebonyClay};
  }
`;

export const StyledHeading = styled.div`
  margin-top: 18px;
  position: relative;

  && {
    h1 {
      font-size: 28px;
      font-weight: 700;

      @media (min-width: ${mobile}px) {
        font-size: 36px;
        text-align: center;
      }

      @media (min-width: ${tablet}px) {
        font-size: 36px;
      }
    }
  }

  @media (min-width: ${tablet}px) {
    margin-top: 0;
  }
`;

export const BannerContainer = styled.div`
  margin-top: 30px;

  a {
    font-size: 14px;
    color: black;
  }
`;

export const VerifyButton = styled(Button).attrs({
  type: "submit",
  // @ts-ignore cannot resolve against default theme
  theme: "default",
  color: "secondary",
})<{ $isSubmitting: boolean }>`
  && {
    margin-right: ${({ $isSubmitting }) => ($isSubmitting ? "60px" : "0")};

    @media (min-width: 768px) {
      margin-right: 0;
    }
  }
`;
