import styled from "styled-components";
import {
  NO_CONTENT_GRAPHIC_TOP_MARGIN_MOBILE,
  NO_CONTENT_GRAPHIC_TOP_MARGIN_TABLET,
} from "styles/global-styles";
import { tablet } from "styles/breakpoints";

export const ComingSoonIconContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${NO_CONTENT_GRAPHIC_TOP_MARGIN_MOBILE}px;

  svg {
    width: 175px;
    height: 210px;
  }

  @media (min-width: ${tablet}px) {
    margin-top: ${NO_CONTENT_GRAPHIC_TOP_MARGIN_TABLET}px;

    svg {
      width: 300px;
      height: 352px;
    }
  }
`;
