import React, { useState, useCallback } from "react";
import { z } from "zod";
import { useHistory } from "react-router-dom";
import { Modal } from "storybook/components/modal";
import { useForm } from "storybook/utils/form";
import { Button } from "storybook/components/button";
import { useRiskResultsKiosk } from "hooks/use-kiosk-risk-results";
import { ModalType, usePatientInterest } from "context/patient-interest";

import { StyledLockIcon, LockContainer, ErrorText } from "./styles";

type SchemaType = z.infer<typeof schema>;

const schema = z.object({
  code: z.string(),
});

export function LockModal() {
  const history = useHistory();
  const results = useRiskResultsKiosk();
  const [isCodeValid, setIsCodeValid] = useState(true);
  const { Form, Input } = useForm<SchemaType>({ schema });
  const { modalType, setModalType } = usePatientInterest();

  const handleSubmit = useCallback(
    (data) => {
      if (data.code.trim() === results?.lockCode.trim()) {
        setIsCodeValid(true);

        switch (modalType) {
          case ModalType.LOCK_RESTART:
            history.push("/institution");
            break;
          case ModalType.LOCK_SCHEDULER:
            setModalType(ModalType.INITIAL);
            break;
          case ModalType.LOCK_PATIENT_HUB:
            history.push("/institution/patient-hub");
            break;
        }
      } else {
        setIsCodeValid(false);
      }
    },
    [history, modalType, results?.lockCode, setModalType]
  );

  return (
    <>
      <Modal.Head alignTitle="center">
        <StyledLockIcon $valid={isCodeValid} />
      </Modal.Head>
      <Modal.Body>
        <LockContainer>
          <Form onSubmit={handleSubmit} disableAutocomplete={true} id="lock">
            <Input
              theme="default"
              label="lock code"
              name="code"
              hideLabel={true}
              hideDirty={true}
              alignText="left"
              type="password"
            />
            {!isCodeValid && (
              <ErrorText variant="body1" color="error">
                Invalid Code
              </ErrorText>
            )}
          </Form>
        </LockContainer>
      </Modal.Body>
      <Modal.Actions alignButtons="left">
        <Button
          theme="default"
          variant="contained"
          color="default"
          type="submit"
          form="lock"
          fullWidth={true}
        >
          Authenticate
        </Button>
      </Modal.Actions>
    </>
  );
}
