import { Link } from "react-router-dom";
import styled from "styled-components";

export const ReportsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 1rem 0 1rem;
`;

export const ReportItem = styled.li`
  padding: 0.75rem 0;
  border-bottom: 1px solid #cac4d0;
`;

export const ReportLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
`;

export const GraphicContainer = styled.div`
  margin-top: 180px;
  display: flex;
  justify-content: center;
`;
